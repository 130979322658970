
import {Link} from "react-router-dom"
  
function CartIsEmpty() {
  return (
    <div class="container-fluid  mt-100">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body cart">
              <div class="col-sm-12 empty-cart-cls text-center">
                <img
                  src="https://i.imgur.com/dCdflKN.png"
                  width="130"
                  height="130"
                  class="img-fluid mb-4 mr-3"
                />
                <p>
                  <strong>Your Cart is Empty</strong>
                </p>
                <p className="fs-12">Add something to make me happy :)</p>
                <Link to={"/shop"}>
                  <span
                    class="btn btn-primary cart-btn-transform m-3"
                    style={{ backgroundColor: "#e74c3c",border:'none' }}
                  >
                    continue shopping
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartIsEmpty;
