export const baseUrl = "https://admin.bookstore.bfcpublications.com";

export const imageUrl =
  "https://bookstoreassets.s3.ap-southeast-1.amazonaws.com/products/";

export const profileImageUrl =
  "https://bookstoreassets.s3.ap-southeast-1.amazonaws.com/user_image/";

export const categoryUrl =
  "https://bookstoreassets.s3.ap-southeast-1.amazonaws.com/category/";

export const apis = {
  signUp: "/api/register",
  login: "/api/login",
  getProducts: "/api/get_products?page=",
  productDetails: "/api/get_product_details/",
  getCategories: "/api/get_category",
  getLanguage: "/api/get_language",
  categoryByProduct_filter: "/api/categoryByProduct_filter?page=",
  languageByproduct_filter: "/api/languageByproduct_filter",
  getCategoriesbyproduct: "/api/category_by_product?page=",
  searchByCategory: "/api/search/",
  searchByPrice: "/api/filter/price",
  searchByText: "/api/products/search?page=",
  
  getCart: "/api/list_cart_data",
  addToCart: "/api/add_to_cart",
  deleteProductFromCart: "/api/remove_product_from_cart",
  updateCartTimes: "/api/update_cart_item",

  //shipping address

  getStates: "/api/get_states",
  getCities: "/api/get_cities",
  addShipping: "/api/add_shiping_address",
  getShippingAddress: "/api/list_shiping_address",
  deleteAddress: "/api/delete_shiping_address",
  updateShippingAddress: "/api/update_shiping_address",

  //order
  createOrder: "/api/create_order_api",
  orderList: "/api/order_list_api",
  orderDetails: "/api/order_details",

  //update profile

  updateProfile: "/api/update_user_profile",
  getUserProfile: "/api/user_profile_detail?id=",
  updatePassword: "/api/update_password",
  createOrderOnline: "/api/order_payment",
  getPaymentStatus: "/api/get_payment_status",
  getTransaction: "/api/get_transaction_list",

  // cancel product
  cancelOrder: "/api/orders-cancel",

  //for forgot password

  forgotPassword: "/api/forget-password",
  verifyOtp: "/api/check-otp",
  createNewPassword: "/api/submit-new-password",

  //for home page products

  homePageProducts: "/api/get_products_home_page",
};
