import  {apis,request,methods} from "../request";
export const httpCategorybyproduct = (id,page=1)=>{
    return request(
      methods.post,
      apis.getCategoriesbyproduct + page,
      { category: id },
      null,
      true
    );
 }
 export const httpcategoryfilter=(body,page=1)=>{
    return request(methods.post,apis.categoryByProduct_filter+page,body,null,true)
}