import Table from "react-bootstrap/Table";
import RecordNotFound from "../utils/record-not-found";
import { Link } from "react-router-dom";

function Transactions({ transactionList, orderStatus }) {
  return (
    <>
      <div className="row">
        <div className="col-6 align-self-center text-start">
          <h4>TRANSACTIONS</h4>
        </div>
        <div className="col-6 text-end">
          <Link to="/shop" class="button-87 float-end" role="button">
            Shop
          </Link>
        </div>
      </div>
      <hr />
      <div className="my-transactions shadow-custom mt-5">
        {transactionList.length > 0 ? (
          <Table responsive>
            <thead className="bg-gray">
              <tr className="text-start">
                <th className="text-redc min-21">Transactions</th>
                <th className="text-redc min-9">Date</th>
                <th className="text-redc min-9">Status</th>
                <th className="text-redc min-9">Total</th>
              </tr>
            </thead>
            <tbody>
              {transactionList.map((el) => {
                return (
                  <tr className="text-start">
                    <td className="d-flex flex-column">
                      <span className="color-span">{el.book_title}</span>
                      {/* <span>28 FEB, 12:19 PM</span> */}
                      <span>
                        <span className="color-span">Order ID</span> :{" "}
                        {el.order_number}
                      </span>
                      <span>
                        {" "}
                        <span className="color-span">
                          Transaction ID{" "}
                        </span>: {el.cf_payment_id}
                      </span>
                    </td>
                    <td>{new Date(el.order_date).toLocaleDateString()}</td>
                    <td>{orderStatus[el.order_status]}</td>
                    <td> ₹{el.total_order_amt}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <RecordNotFound />
        )}
      </div>
    </>
  );
}

export default Transactions