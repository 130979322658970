import React,{useContext, useState} from "react";
import { FaPlus, FaMinus } from 'react-icons/fa';
import { BiTrash } from 'react-icons/bi';
import { Link } from "react-router-dom";
import { CartContext } from "../context/CartContext"
import { UserContext} from "../context/User"
import {imageUrl} from "../request"
import DeletePopup from "../utils/delete-pop";
import { Button } from "react-bootstrap";
import CartEmpty from "../utils/cart-empty"

const Cart = () => {
  
  const { cart, removeFromCart, incrementByOne } = useContext(CartContext);
  const { isUserValid } = useContext(UserContext);
  const [show, setShow] = useState(false)
  const [itemId, setItemId] = useState("");
  const handleClose = ()=>setShow(false)

   const fetchTotal = () => {
     let total = 0;

     if (cart) {
       cart.forEach((el) => {
         total += parseInt(el.quantity) * parseInt(el.mrp);
       });
     }

     return total;
  };
  
  const deleteProductFromCart = async (id)=>{
    await removeFromCart(id)
    handleClose()
  }

  const openDeletePopup = (id) => {
    setItemId(id);
    setShow(true);
  }


  return (
    <>
      <DeletePopup
        id={itemId}
        handleClose={handleClose}
        submit={deleteProductFromCart}
        show={show}
      />
      <nav aria-label="breadcrumb" class="breadcrumbs large-font">
        <ol class="breadcrumb pt-2 ps-3">
          <li class="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Cart
          </li>
        </ol>
      </nav>
      <section className="py-5  bg-light-blue">
        <div className="container px-4">
          <h2 class="mb-5 heading-text mx-lg-auto text-center">
            Shopping Cart
          </h2>
          <div className="row justify-content-center">
            <div className="col-md-10 cart ">
              {/* ===========Cart=========== */}

              {cart &&
                cart.map((el) => {
                  return (
                    <div className="row cart-shadaw py-2 mb-4 bg-white align-items-stretch rounded">
                      <div className="col-md-2">
                        <Link to={`/product/${el.id}`}>
                          <img
                            src={imageUrl + el.image}
                            alt="team-3"
                            class="img-fluid rounded"
                          />
                        </Link>
                      </div>

                      <div className="col-md-5 align-self-center mt-4 mt-lg-0 fsc-20">
                        <h6>
                          <a href="">
                            <b>{el.book_title}</b>
                          </a>
                        </h6>
                        <p className="pt-2">
                          <b>By :</b> {el.author_name}
                        </p>
                      </div>

                      <div className="col-md-2 d-flex align-self-center text-center">
                        <button
                          className="btn-quantity"
                          onClick={() => incrementByOne(el, -1)}
                          disabled={el.quantity == 1}
                        >
                          <FaMinus />
                        </button>

                        <p className="px-4 pt-2">
                          <b> {el.quantity} </b>
                        </p>
                        <button
                          className="btn-quantity"
                          onClick={() => incrementByOne(el, 1)}
                        >
                          <FaPlus />
                        </button>
                      </div>
                      <div className="col-md-2 align-self-center text-center mt-4 mt-lg-0">
                        <p>
                          <b>
                            ₹ {el.mrp} * {el.quantity}
                          </b>
                        </p>
                      </div>
                      <div
                        className="col-md-1 align-self-center textc-end "
                        onClick={() => openDeletePopup(el.id)}
                      >
                        <Button variant="white">
                          <BiTrash className="fs-3 text-redc del" />
                        </Button>
                      </div>
                    </div>
                  );
                })}

              <hr />

              {cart?.length > 0 ? (
                <div className="text-end">
                  <ul className="list-unstyled d-flex justify-content-end">
                    {/* <li>
                    <b>Shipping Items :</b>
                  </li> */}
                    <li>
                      <span className="ps-5"> </span>
                    </li>
                  </ul>
                  <ul className="list-unstyled d-flex justify-content-end">
                    <li>
                      <b>Grand Total :</b>
                    </li>
                    <li>
                      <span className="ps-5">₹{fetchTotal()}</span>
                    </li>
                  </ul>
                  <hr />
                  <div className="d-flex justify-content-end">
                    <Link
                      class="button-87"
                      to={`${isUserValid() ? "/checkout" : "/signin"}`}
                      state={isUserValid() ? null : { path: "/checkout" }}
                    >
                      Checkout
                    </Link>
                  </div>
                </div>
              ) : (
                <CartEmpty />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Cart; 