import { Link } from "react-router-dom";
import RecordNotFound from "../utils/record-not-found";

function Address({ addressList }) {
  return (
    <>
      <div className="row">
        <div className="col-6 align-self-center text-start">
          <h4>SHIPPING ADDRESS</h4>
        </div>
        <div className="col-6 text-end">
          <Link to="/shop" class="button-87 float-end" role="button">
            Shop
          </Link>
        </div>
      </div>
      <hr />
      <div className="billing-details">
        {addressList.length > 0 ? (
          <div className="billing-address">
            {/* <div className="add-billing-address-btn d-flex justify-content-between mb-4">
                        <h5 className="text-redc">Select Address</h5>
                      </div> */}

            {addressList.map((el) => {
              return (
                <div className="billing-address-detail">
                  <div class="form-check mb-3">
                    {/* <input
                                class="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault1"
                              /> */}
                    <label
                      class="form-check-label fw-bold"
                      for="flexRadioDefault1"
                    >
                      <span className="fw-bold color-span">Name : </span>
                      {el.recipient_name}
                    </label>
                  </div>
                  <div className="billing-fulladdress ps-4 address-item">
                    <p>
                      <span className="fw-bold color-span">Address : </span>
                      {el.address}
                    </p>
                    <p>
                      <span className="fw-bold color-span">Mobile : </span>
                      {el.recipient_mobile}
                    </p>
                    <p>
                      <span className="fw-bold color-span">State : </span>
                      {el.state_name}
                    </p>
                    <p>
                      <span className="fw-bold color-span">City : </span>
                      {el.city_name}
                    </p>
                    <p>
                      <span className="fw-bold color-span">Postal Code : </span>
                      {el.postal_code}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <RecordNotFound />
        )}
      </div>
    </>
  );
}

export default Address;
