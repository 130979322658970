import React, { useState, useContext, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import logo from "../assets/img/logo/bfc-logo.png";
import Navbar from "react-bootstrap/Navbar";
import { FaSearch } from "react-icons/fa";
import { BsHandbag } from "react-icons/bs";
import { NavLink } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import CartBox from "../components/header-cartbox";
import ProfileDetails from "../components/profile-details";
import { UserContext } from "../context/User";
import { CartContext } from "../context/CartContext";
import { profileImageUrl } from "../request/urls";
import profile_image from "../assets/img/others/profile.png";

function Header() {
  const [scroll, setScroll] = React.useState(false);
  const { user, logout, token } = useContext(UserContext);
  const { cart, removeFromCart } = useContext(CartContext);

  const [searchproduct, setsearchProduct] = useState("");

  const navigate = useNavigate();

  const goToShop = (e) => {
    navigate("/shop/" + searchproduct);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  useEffect(() => {
    if (searchproduct) {
      let myTime = setTimeout(() => {
        navigate("/shop/" + searchproduct);
      }, 600);

      return () => clearTimeout(myTime);
    }
  }, [searchproduct]);

  // console.log("searchproduct form header ", searchproduct, "user ", user);

  const image = user?.profile_image
    ? profileImageUrl + user.profile_image
    : profile_image;

  return (
    <Navbar
      expand="lg"
      sticky="top"
      className={
        scroll ? "header-shrink py-0 bg-white" : " py-0 bg-white showdow-h"
      }
    >
      <Container fluid>
        <Navbar.Brand>
          <Link to="/">
            <img src={logo} alt="logo" className="img-fluid logo" />
          </Link>
        </Navbar.Brand>
        {/* mobile view profile*/}

        {user ? (
          <>
            <NavLink
              to=""
              className="cart-info pofile-pic-head-link d-block d-lg-none my-2"
            >
              <img
                src={image}
                alt=""
                srcset=""
                className="img-fluid pofile-pic img-circle pofile-pic-head"
              />
              {/* <FaUser className="fs-3 text-redc" style={{width:"100px"}} /> */}
              <ProfileDetails user={user} logout={logout} image={image} />
            </NavLink>
            <NavLink as={Link} to="#" className="cart-info d-block d-lg-none">
              <BsHandbag className="fs-3 text-redc" />
              <CartBox
                cart={cart}
                removeFromCart={removeFromCart}
                token={token}
              />
              <span className="cart-item">{cart ? cart.length : 0}</span>
            </NavLink>
          </>
        ) : (
          <NavLink
            as={Link}
            to="/signin"
            className="btn login-btn d-block d-lg-none"
          >
            Login
          </NavLink>
        )}

        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="mx-auto my-2 my-lg-0" navbarScroll>
           
            <form autocomplete="off">
               <div class="search-box mb-2 mb-lg-0">
                <input
                  className="search-input"
                  type="text"
                  value={searchproduct}
                  onChange={(e) => {
                    setsearchProduct(e.target.value);
                  }}
                  placeholder="Search by Book Title or Author Name"
                />
                <button class="search-btn" onClick={goToShop}>
                  <FaSearch className="fs-5 mtc-n2" />
              </button>
              </div>
              </form>
            
          </Nav>

          <ul className="lst-none d-flex align-items-center  mt-2 mb-2">
            {user ? (
              <>
                <li className="me-5 mt-3 mt-lg-0  d-none d-lg-block">
                  <NavLink to="" className="cart-info pofile-pic-head-link">
                    <img
                      src={image}
                      alt=""
                      srcset=""
                      className="img-fluid pofile-pic img-circle pofile-pic-head"
                    />
                    {/* <FaUser className="fs-3 text-redc" style={{width:"100px"}} /> */}
                    <ProfileDetails user={user} logout={logout} image={image} />
                  </NavLink>
                </li>
              </>
            ) : (
              <li className="me-5 mt-3 mt-lg-0 ">
                <NavLink as={Link} to="/signin" className="btn login-btn ">
                  Login
                </NavLink>
              </li>
            )}

            <li className="me-5 position-relative d-none d-lg-block">
              {/* {cart && cart.length > 0 ? ( */}
              <NavLink as={Link} to="#" className="cart-info">
                <BsHandbag className="fs-3 text-redc" />
                <CartBox
                  cart={cart}
                  removeFromCart={removeFromCart}
                  token={token}
                />
                <span className="cart-item">{cart ? cart.length : 0}</span>
              </NavLink>
            </li>
          </ul>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
