import { Route } from "react-router-dom";
import Redirect from "./redirect";

const isUserValid = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user) {
    if (new Date() > new Date(user.expires)) {
      return false;
    } else {
      return true;
    }
  }
};

function Guard(Component, path, permission, props = {}) {
  if (permission.authRequire && !isUserValid()) {
    return <Route path={path} element={<Redirect path="/signin" />} />;
  } else if (permission.unAuthRequire && isUserValid()) {
    return <Route path={path} element={<Redirect path="/" />} />;
  } else {
    return <Route path={path} element={<Component {...props} />} />;
  }
}

export default Guard;
