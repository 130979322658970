import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import RecordNotFound from "../utils/record-not-found";


function Order({ orderList, createTime, orderStatus }) {
  return (
    <>
      <div className="row">
        <div className="col-6 align-self-center text-start">
          <h4>MY ORDER</h4>
        </div>
        <div className="col-6 text-end">
          <Link to="/shop" class="button-87 float-end" role="button">
            Shop
          </Link>
        </div>
      </div>
      <hr />

      <div className="my-order shadow-custom">
        {orderList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="text-redc">Order</th>
                <th className="text-redc min-9">Date</th>
                <th className="text-redc">Status</th>
                <th className="text-redc">Total</th>
                {/* <th className="text-redc">Time</th> */}
              </tr>
            </thead>
            <tbody>
              {orderList.map((el) => {
                return (
                  <tr>
                    <td>
                      <Link to={`/order-summary/${el.id}`}>
                        {el.order_number}
                      </Link>
                    </td>
                    <td>{new Date(el.date).toLocaleDateString()}</td>
                     {orderStatus[el.order_status]}
                    <td>₹{el.total_order_amt}</td>
                    {/* <td>
                      <span className=" orderc">{createTime(el.date)}</span>
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <div>
            <RecordNotFound />
          </div>
        )}
      </div>
    </>
  );
}

export default Order;
