import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { httpChangePassword } from "../apimethod";
import { toast } from "react-toastify";
import { isPassword } from "../utils/validations";

function ChangePassword({ setLoader }) {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  //error states

  const [errorOldPassword, setErrorOldPassword] = useState("");
  const [errorNewPassword, setErrorNewPassword] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");

  //token

  const token = localStorage.getItem("token");

  const onSubmit = (e) => {
    e.preventDefault();

    let isError = false;

    if (!oldPassword) {
      setErrorOldPassword("Current password is mandatory");
      isError = true;
    } else if (!isPassword(oldPassword)) {
      setErrorOldPassword("Please enter valid password");
      isError = true;
    } else {
      setErrorOldPassword("");
    }

    if (!newPassword) {
      setErrorNewPassword("New password is mandatory");
      isError = true;
    } else if (!isPassword(newPassword)) {
      setErrorNewPassword("Please enter valid password");
      isError = true;
    } else {
      setErrorNewPassword("");
    }

    if (!confirmPassword) {
      setErrorConfirmPassword("Confirm password is mandatory");
      isError = true;
    } else if (!isPassword(confirmPassword)) {
      setErrorConfirmPassword("Please enter valid password");
      isError = true;
    } else if (confirmPassword !== newPassword) {
      setErrorConfirmPassword("Confirm password and new password must match.");
      isError = true;
    } else {
      setErrorConfirmPassword("");
    }

    if (!isError) {
      setLoader(true);
      httpChangePassword(
        {
          old_password: oldPassword,
          new_password: newPassword,
          confirm_password: confirmPassword,
        },
        token
      ).then((res) => {
        if (!res.data) {
          setLoader(false);
          toast.error(res.error?.response.data.message);
        } else {
          setLoader(false);
          toast.success(res.data.message);
          setOldPassword("");
          setNewPassword("");
          setConfirmPassword("");
        }
      });
    }
  };

  return (
    <>
      <h4>CHANGE PASSWORD</h4>
      <hr />
      <div className="change-pwd">
        <div className="row justify-content-center">
          <div className="col-md-6 shadow-custom py-4">
            <Form as={Row}>
              <div className="mb-4 form-group" controlId="formCuttentPassword">
                <input
                  className="form-control"
                  type="password"
                  value={oldPassword}
                  onChange={(e) => {
                    setOldPassword(e.target.value);
                  }}
                  placeholder="Current Password*"
                />
                <span style={{ color: "red" }}>{errorOldPassword}</span>
              </div>

              <div className="mb-4 form-group" controlId="formNewPassword">
                <input
                  className="form-control"
                  type="password"
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                  placeholder="New Password*"
                />
                <span style={{ color: "red" }}>{errorNewPassword}</span>
              </div>
              <div className="mb-4 form-group" controlId="formConfirmPassword">
                <input
                  className="form-control"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                  placeholder="Confirm Password*"
                />
                <span style={{ color: "red" }}>{errorConfirmPassword}</span>
              </div>
              <div className="mb-5 form-group" controlId="formButtyon">
                <div className="d-grid gap-2">
                  <Button
                    variant="success"
                    className="button-87"
                    onClick={onSubmit}
                  >
                    Change Password
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
