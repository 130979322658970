import { apis, request, methods } from "../request";

const header = {
  headers: { Authorization: null },
};


export const httpGetStates = (token) => {
  header.headers.Authorization = `Bearer ${ token }`;
  return request(methods.get,apis.getStates,null,header)
}

export const httpGetCities = (body,token) => {
header.headers.Authorization = `Bearer ${token}`;
  return request(methods.post,apis.getCities,body,header)
}

export const httpAddShippingAddress = (body,token) =>{
  header.headers.Authorization = `Bearer ${token}`;
  return request(methods.post,apis.addShipping,body,header)
}

export const httpGetShippingAddress = (token) => {
  header.headers.Authorization = `Bearer ${token}`;
  return request(methods.get,apis.getShippingAddress,null,header)
}

export const httpDeleteShippingAddress = (body,token) => {
  header.headers.Authorization = `Bearer ${token}`;
  return request(methods.post, apis.deleteAddress, body, header);
}

export const httpUpdateShippingAddress = (body,token) => {
  header.headers.Authorization = `Bearer ${token}`;
  return request(methods.post, apis.updateShippingAddress, body, header);
}