import { request, apis, methods } from "../request";

export function fetchCart(header) {
  return request(methods.get, apis.getCart, null, header);
}

export function saveCart(body, header) {
  return request(methods.post, apis.addToCart, body, header);
}

export function deleteProductFromCart(body, header) {
  return request(methods.post, apis.deleteProductFromCart, body, header);
}

export function updateCartItems(body, header) {
  return request(methods.post, apis.updateCartTimes, body, header);
}
