
import { useState,useContext} from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { httpCreateNewPassword } from "../apimethod";
import { toast } from "react-toastify";
import logo from "../assets/img/logo/bfc-logo-removebg-preview.png";
import forgotImage from "../assets/img/forgot-pass.png";
import { useLocation, useNavigate } from "react-router-dom"
import { UserContext } from "../context/User"
import {isPassword} from "../utils/validations"

function ChangePassword() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  //error states
  const [errorNewPassword, setErrorNewPassword] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");


  // getting hash value from forgot page another page

  const {state} = useLocation()
  
  //navigator
  const navigate = useNavigate()

  const {setLoader} = useContext(UserContext)

  const onSubmit = (e) => {
    e.preventDefault();

    let isError = false;

    if (!newPassword) {
      setErrorNewPassword("New password is mandatory");
      isError = true;
    } else if (!isPassword(newPassword)) {
      setErrorNewPassword("Please enter valid password");
      isError = true;
    } else {
      setErrorNewPassword("");
    }

    if (!confirmPassword) {
      setErrorConfirmPassword("Confirm password is mandatory");
      isError = true;
    } else if (confirmPassword !== newPassword) {
      setErrorConfirmPassword("Confirm password and new password must match.");
      isError = true;
    } else {
      setErrorConfirmPassword("");
    }

    if (!isError) {
      setLoader(true);
      httpCreateNewPassword(
        { 
          enc_email:state,
          new_pass: newPassword,
          c_pass: confirmPassword,
        }
      ).then((res) => {
        if (!res.data) {
          setLoader(false);
          toast.error("Failed to create password");
        } else {
          setLoader(false);
          toast.success(res.data.message)
          navigate("/signin")
        }
      });
    }
  };

  return (
    <>
      <section className=" login-bg pt-3">
        <div className="container">
          <div className="brand-logo ">
            <a href="/">
              <img src={logo} alt="" srcset="" className="img-fluid" />
            </a>
          </div>

          <div className="change-pwd ">
            <div className="row ">
              <div className="col-md-7">
                <img src={forgotImage} alt="" srcset="" className="img-fluid" />
              </div>
              <div className="col-md-5 shadow-custom py-4 align-self-center">
                <h4 class="pb-3 text-redc ">Create Password</h4>

                <Form as={Row}>
                  <Form.Group className="mb-4" controlId="formNewPassword">
                    <Form.Control
                      type="password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      placeholder="New Password*"
                    />
                    <span style={{ color: "red" }}>{errorNewPassword}</span>
                  </Form.Group>
                  <Form.Group className="mb-4" controlId="formConfirmPassword">
                    <Form.Control
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      placeholder="Confirm Password*"
                    />
                    <span style={{ color: "red" }}>{errorConfirmPassword}</span>
                  </Form.Group>
                  <Form.Group className="mb-5" controlId="formButtyon">
                    <div className="d-grid gap-2">
                      <Button
                        variant="success"
                        className="button-87"
                        style={{ width: "20%" }}
                        onClick={onSubmit}
                      >
                        submit
                      </Button>
                    </div>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ChangePassword;
