import { Dna } from "react-loader-spinner";

function Loader({ loader }) {
  return (
    <div id="overlay" style={{ display: loader ? "block" : "none" }}>
      <Dna
        visible={true}
        height="80"
        width="80"
        ariaLabel="dna-loading"
        wrapperStyle={{}}
        wrapperClass="dna-wrapper"
      />
    </div>
  );
}

export default Loader;
