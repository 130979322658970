import React, { useEffect, useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { httpOrderDetails, httpCancelOrder } from "../apimethod";
import { UserContext } from "../context/User";
import { toast } from "react-toastify";
import { imageUrl } from "../request/urls";
import DeletePop from "../utils/delete-pop";
import { orderStatus } from "../utils/order-status";
import TrackOrder from "../components/track-order";

const OrderSummary = () => {
  const { order_id } = useParams();
  const { setLoader } = useContext(UserContext);
  const [products, setProducts] = useState([]);
  const [shippingDetails, setShippingDetails] = useState(null);

  // open delete modal
  const [show, setShow] = useState(false);
  const token = localStorage.getItem("token");

  //update order
  const [orderDetails, setOrderDetails] = useState("");

  useEffect(() => {
    setLoader(true);
    httpOrderDetails({ order_id }, token).then((res) => {
      if (!res.data) {
        setLoader(false);
        toast("order not fetched");
      } else {
        setLoader(false);
        setShippingDetails(res.data.data[0]);
        setProducts(res.data.data);
      }
    });
  }, [orderDetails]);

  const paymentType = {
    1: "COD",
    2: "Paid",
  };

  const fetchTotal = () => {
    let total = 0;
    products.forEach((el) => {
      total += parseInt(el.quantity) * parseInt(el.price);
    });
    return total;
  };

  const handleCancelOrder = (id) => {
    setLoader(true);
    httpCancelOrder({ order_id: id }, token).then((res) => {
      if (!res.data) {
        toast.error("failed to cancel the order");
        setLoader(false);
        setShow(false);
      } else {
        setLoader(false);
        setShow(false);
        setOrderDetails(res.data);
      }
    });
  };

  const cancelBtn = () => {
    if (
      (products.length && products[0].order_status == 5) ||
      (products.length && products[0].order_status == 4)
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <DeletePop
        id={order_id}
        show={show}
        handleClose={() => setShow(false)}
        submit={handleCancelOrder}
      >
        Are you sure you want to cancel
      </DeletePop>
      <nav aria-label="breadcrumb" class="breadcrumbs large-font">
        <ol class="breadcrumb pt-2 ps-3">
          <li class="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Order Summary
          </li>
        </ol>
      </nav>
      <section className="mx-2">
        <div className="container mb-5 summary ">
          <div className="row pt-4 flex-wrap-reverse">
            <div className="col-md-7">
              <div className="row mb-5 mx-lg-3  align-items-stretch">
                <div className="col-6 fsc-12">
                  <p className="mb-0">
                    <span className="text-rale">OrderID: </span>
                    {shippingDetails?.order_number}
                  </p>
                  <p className="mb-0">
                    <span className="text-rale">Placed On: </span>
                    {new Date(shippingDetails?.order_date).toLocaleDateString()}
                  </p>
                </div>
                {cancelBtn() ? (
                  <div className="col-6 text-end align-self-center">
                    <button
                      className="cancel-btn"
                      onClick={() => setShow(true)}
                    >
                      Cancel order
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>

              {products.map((el) => {
                return (
                  <div className="row cart-shadaw mb-2 py-2 bg-white align-items-stretch rounded mx-lg-3 ">
                    <div className="col-md-2">
                      <Link to={`/product/` + el.product_id}>
                        <img
                          src={imageUrl + el.image}
                          alt="team-3"
                          class="img-fluid rounded"
                        />
                      </Link>
                    </div>
                    <div className="col-md-4 align-self-center mt-4 mt-lg-0 fsc-20">
                      <h5>
                        <a href="">{el.book_title}</a>
                      </h5>
                      <p className="mb-0">
                        <span className="text-rale">By :</span> {el.author_name}
                      </p>
                      <p className="mb-0">
                        <span className="text-rale">Quantity:</span>
                        {el.quantity}
                      </p>
                      <p className="">
                        <span className="text-rale">Price:</span>
                        <b>₹ {el.price}</b>
                      </p>
                    </div>

                    <div className="col-md-6 text-end align-self-center">
                      <p>{orderStatus[el.order_status]}</p>
                      <p className="text-success">
                        <b>{paymentType[el.payment_type]}</b>
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="col-md-5 shadow-order  p-4  rounded mb-4 mb-lg-0">
              <h4 class="mb-5 heading-text mx-auto text-center">
                Order Summary
              </h4>

              <div className="row">
                <div className="col-4 fsc-12">Order Sub Total</div>
                <div className="col-1">=</div>
                <div className="col-7">
                  <b>₹{fetchTotal()}</b>
                </div>
              </div>
              <div className="row border-dash py-2">
                <div className="col-4 fsc-12 ">Discount</div>
                <div className="col-1">=</div>
                <div className="col-7">
                  <b>₹0</b>
                </div>
              </div>
              <div className="row border-dash py-2">
                <div className="col-4  fsc-12">Shipping</div>
                <div className="col-1">=</div>
                <div className="col-7">
                  <b>₹0</b>
                </div>
              </div>
              <div className="row border-dash py-2">
                <div className="col-4 fsc-12">
                  <b className="text-redc">Total Amount</b>
                </div>
                <div className="col-1">=</div>
                <div className="col-7">
                  <b>₹{fetchTotal()}</b>
                </div>
              </div>
              <h6 className=" py-3  " style={{ fontWeight: "bolder" }}>
                Shipping Details
              </h6>
              <p>
                <span style={{ fontWeight: "bold", color: "#f44556" }}>
                  Recipient Name :
                </span>
                {shippingDetails?.recipient_name}
              </p>
              <p>
                <span style={{ fontWeight: "bold", color: "#f44556" }}>
                  Address :
                </span>
                {shippingDetails?.address}
              </p>
              <p>
                <span style={{ fontWeight: "bold", color: "#f44556" }}>
                  City Name :
                </span>
                {shippingDetails?.city_name}
              </p>

              <p>
                <span style={{ fontWeight: "bold", color: "#f44556" }}>
                  Postal Code :
                </span>
                {shippingDetails?.postal_code}
              </p>

              <p>
                <span style={{ fontWeight: "bold", color: "#f44556" }}>
                  Recipient Mobile :
                </span>
                {shippingDetails?.recipient_mobile}
              </p>

              <p className="border-dash pb-3">
                <span style={{ fontWeight: "bold", color: "#f44556" }}>
                  State Name :
                </span>
                {shippingDetails?.state_name}
              </p>
              <div className="">
                <h6>
                  <b>Order Status</b>
                </h6>
                <TrackOrder orderStatus={shippingDetails?.order_status} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default OrderSummary;
