import { useState, useEffect } from "react";

function TrackOrder({ orderStatus }) {
  const [statusList, setStatusList] = useState([
    "progtrckr-todo",
    "progtrckr-todo",
    "progtrckr-todo",
    "progtrckr-todo",
  ]);

  const getStatus = () => {
    if (orderStatus < 5) {
      for (let i = 0; i < orderStatus; i++) {
        statusList[i] = "progtrckr-done";
      }
      setStatusList([...statusList]);
    }
  };

  useEffect(() => {
    if (orderStatus) {
      getStatus();
    }
  }, [orderStatus]);

  if (!orderStatus) {
    return null;
  }

  return (
    <>
      <ol class="progtrckr" data-progtrckr-steps="4">
        <li class={statusList[0]}>Pending</li>
        <li class={statusList[1]}>Accepted</li>
        <li class={statusList[2]}>Shipped</li>
        <li class={statusList[3]}>Delivered</li>
      </ol>
    </>
  );
}

export default TrackOrder;
