import React, { useState, useEffect } from "react";
import OTPInput, { ResendOTP } from "otp-input-react";

const Otp_Input = ({ OTP, setOTP, sendOtp, userComes }) => {
  const [timer, setTimer] = useState(60);

  useEffect(() => {
    if (userComes) {
      if (timer > 0) {
        setTimeout(() => {
          setTimer(timer - 1);
        }, 1000);
      }
    } else {
      setTimer(60);
    }
  }, [timer, userComes]);

  const resetTimer = () => {
    setTimer(60);
    sendOtp();
  };

  const renderButton = (buttonProps) => {
    return (
      <div className="pt-2">
        <span className="pe-3">{timer}</span>
        <button
          disabled={timer != 0}
          className="fs-13 fs-sm-10 border-0"
          onClick={resetTimer}
        >
          Didn’t received OTP? <b> Resend OTP </b>
        </button>
      </div>
    );
  };

  return (
    <>
      <div className="form-group">
        {/* <label htmlFor="otp" className="pb-2 fw-600">
          Enter OTP
        </label> */}
        <OTPInput
          className="otp-inp justify-content-lg-center"
          value={OTP}
          onChange={setOTP}
          autoFocus
          OTPLength={6}
          otpType="number"
          disabled={false}
        />
        {renderButton()}
      </div>
    </>
  );
};
export default Otp_Input;
