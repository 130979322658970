export const stockQuantity = (productDetails) => {
  if (productDetails.type === 3) {
    return (
      <li style={{ fontWeight: "bolder", color: "green" }}>
        Coming soon
      </li>
    );
  }

  if (productDetails?.stock_quantity < 1) {
    return <li style={{ fontWeight: "bolder", color: "red" }}>Out Of Stock</li>;
  } else {
    return (
      <li style={{ fontWeight: "bolder", color: "green" }}>
        In Stock : {productDetails?.stock_quantity}
      </li>
    );
  }
};
