
export const isEmail = (input) => {
 let regexp =
     /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
 return regexp.test(input)
}

export const isName = (input) => {
  let nameRegex = /^[A-Za-z\s.'-]+$/;
  return nameRegex.test(input)
}

export const isMobileNo = (input) => {
  let pattern = /^(0|[+91]{3})?[7-9][0-9]{9}$/;
  return pattern.test(input)
}

export const isPassword = (input)=>{
   let pattern = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
   return pattern.test(input);
}

export const textWrapper = (message) => {
  if (message.length > 22) {
    return message.slice(0, 22) + "...";
  } else {
    return message;
  }
};