import React, { useState, useContext } from "react";
import forgotImage from "../assets/img/forgot-pass.png";
import logo from "../assets/img/logo/bfc-logo-removebg-preview.png";
import { GiArchiveRegister } from "react-icons/gi";
import { Link, useNavigate } from "react-router-dom";
import { CartContext } from "../context/CartContext";
import { isEmail } from "../utils/validations";
import Otp_Input from "../components/resend-otp";
import { httpForgotPassword, httpVerifyOtp } from "../apimethod";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { setLoader } = useContext(CartContext);

  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [otpShow, setOtpShow] = useState(false);
  const [otp,setOtp] = useState("")
  const [startTimer,setStartTimer] = useState(false)

  const checkValidation = () => {
    let hasError = false;
    if (email == "") {
      setErrorEmail("Please enter email");
      hasError = true;
    } else {
      if (!isEmail(email.toLowerCase())) {
        setErrorEmail("Please enter valid email");
        hasError = true;
      } else {
        setErrorEmail("");

      }
    }
    return hasError;
  };

  const openOtp = async () => {
    setOtpShow(true);
    setStartTimer(true)
  };

  const sendOtp = () => {
    setLoader(true);
    httpForgotPassword({ email }).then((res) => {
      if (!res.data) {
        toast.error(res.error.response?.data?.message);
        setLoader(false);
      } else {
        setLoader(false);
        toast.success(res.data.message)
        openOtp();
      }
    });
  }

  const callSendOtpOrVerifyOtp = (e) => {
    e.preventDefault();
    
    if (checkValidation()) {
      return;
    }

    sendOtp()
  }

  const verifyOtp = (e) => {
    e.preventDefault()
    if (!otp) {
        toast.warn("Enter OTP");
        return;
    }

    if (otp.length != 6) {
      toast.warn("Invalid OTP")
      return;
    }

    setLoader(true)
    httpVerifyOtp({ v_otp: otp, email }).then(res => {
      if (!res.data) {
        setLoader(false)
        toast.error(res.error.response.data.message);
      } else {
        setLoader(false)
        navigate("/create-new-password", { state:  res.data.hash })
      }
   })
  }

  return (
    <>
      <section className=" login-bg pt-3">
        <div className="container">
          <div className="brand-logo ">
            <a href="/">
              <img src={logo} alt="" srcset="" className="img-fluid" />
            </a>
          </div>
          <div className="row align-items-stretch pt-3">
            <div className="col-md-6" style={{ marginTop: "50px" }}>
              <img src={forgotImage} alt="" srcset="" className="img-fluid" />
            </div>
            <div className="col-md-6 align-self-center pt-2">
              <h4 className="pb-3 text-redc ">Forgot Password</h4>
              <form>
                <div className="form-group mb-4">
                  <input
                    class="search-input si"
                    type="email"
                    name="email"
                    disabled={otpShow}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter Your Email"
                  />
                  <span style={{ color: "red" }}>{errorEmail}</span>
                </div>

                <div className={`form-group mb-4 ${otpShow ? "" : "d-none"}`}>
                  <Otp_Input
                    OTP={otp}
                    setOTP={setOtp}
                    userComes={startTimer}
                    sendOtp={sendOtp}
                  />
                </div>

                <div className="form-group mt-4">
                  <button
                    type="submit"
                    class="button-86 fw-bold"
                    onClick={otpShow?verifyOtp:callSendOtpOrVerifyOtp}
                    role="button"
                  >
                    {otpShow ? "verify" : "Send OTP"}
                    <GiArchiveRegister className="ms-2 fs-5" />
                  </button>
                </div>
                <hr />
                <p className="fs-14 text-rale">
                  Don't have an account? New customer?
                  <Link to="/signup" className="login-a">
                    <b>Create your account</b>
                  </Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ForgotPassword;
