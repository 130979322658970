import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CgLogOff } from "react-icons/cg";
import { FaUser } from "react-icons/fa";
import { CartContext } from "../context/CartContext";
import {textWrapper} from "../utils/validations"

const ProfileDetails = ({ user, logout,image }) => {
  const { clearCart } = useContext(CartContext);

  const deleteCartAndLogout = () => {
    clearCart();
    logout();
  };

 
  return (
    <>
      <div className="cart-box profile-d">
        <div className="card-info position-relative">
          <ul className="ps-0 list-unstyled ">
            <li>
              <Link to="/my-account">
                <div className="d-flex align-items-stretch pb-2 ps-2">
                  {/* <img
                    src={image}
                    alt=""
                    srcset=""
                    className="img-fluid pofile-pic"
                  /> */}
                  <p className="align-self-center mb-0 ms-4 ">
                    {" "}
                    <span className="fw-bold">{user ? user.name : ""}</span>
                    <br />
                    <a href="#" className="email">
                      {user ? textWrapper(user.email) : ""}
                    </a>
                  </p>
                </div>
              </Link>
            </li>
            <li className="border-top py-2">
              <Link to="/my-account" className="text-black">
                <span className="px-3 fs-30 ">
                  <FaUser className="iconfa text-redc" />
                </span>
                My Account
              </Link>
            </li>
            <li className="border-top py-2" onClick={deleteCartAndLogout}>
              {/* <Link to="/signin" className="text-black"> */}
              <span className="px-3 fs-30">
                <CgLogOff className="iconfa text-redc" />
              </span>
              Logout
              {/* </Link> */}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default ProfileDetails;
