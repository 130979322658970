import React, { useEffect, useState, useContext } from "react";
import { HiStar } from "react-icons/hi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaPlus, FaMinus } from "react-icons/fa";
import { BsHandbag } from "react-icons/bs";
import { httpProductDetails, httpCategorybyproduct } from "../apimethod";
import { CartContext } from "../context/CartContext";
import { UserContext } from "../context/User";
import { imageUrl } from "../request/urls";
import OwlCarousel from "react-owl-carousel";
import { stockQuantity } from "../utils/out-of-stock.message";

const Product = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { addToCart, setLoader } = useContext(CartContext);
  const { addRecentViewProduct } = useContext(UserContext);
  const [quantity, setQuantity] = useState(1);
  const [productDetails, setProductDetails] = useState("");
  const [relatedProducts, setRelatedProducts] = useState([]);

  const loadRelatedCategory = (product, pages) => {
    httpCategorybyproduct(product.cat_id, pages).then((res) => {
      if (res.data) {
        const data = res.data.data.filter((el) => el.id != product.id);
        setRelatedProducts(data);
        setLoader(false);
      }
    });
  };

  useEffect(() => {

    let regex = /[a-zA-Z]/g;
    if (regex.test(id)){
        navigate("/");
        return;
    }

    // if (!id) {
    //   navigate("/");
    //   return;
    // }

    setLoader(true);
    httpProductDetails(id).then((res) => {
      setProductDetails(res.data.data);
      loadRelatedCategory(res.data.data, 1);
      addRecentViewProduct(res.data.data);
    });
  }, [id]);

  const quantityPlus = () => {
    if (quantity < productDetails.stock_quantity) setQuantity(quantity + 1);
  };

  const quantityMinus = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const goToProduct = () => {
    addToCart({ ...productDetails, quantity: quantity }).then((val) => {
      if (val != false) {
        navigate("/cart");
      }
    });
  };

  const bookTypes = {
    1: "Paperback",
    2: "Hardcover",
    3: "Hardbound",
    4: "none",
  };

  return (
    <>
      <nav aria-label="breadcrumb" class="breadcrumbs large-font">
        <ol class="breadcrumb pt-2 ps-3">
          <li class="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Product
          </li>
        </ol>
      </nav>
      <section className="py-5  ">
        <div className="container">
          <div className="row">
            <div className="col-md-3 mb-4">
              <img
                src={imageUrl + productDetails?.image}
                alt="team-3"
                class="img-fluid rounded"
              />
            </div>
            <div className="col-md-6">
              <h3 className="">{productDetails?.book_title}</h3>
              <div className="d-flex">
                <div className=" text-yellow-darker fs-5 fw-bold">
                  <HiStar />
                  4.2
                </div>
                <div className="ms-3 fs-6 pt-1">
                  <b>By :</b> {productDetails?.author_name}
                </div>
              </div>
              <hr />
              <b className="text-redc">Specifications :</b>
              <ul className="pt-2">
                {stockQuantity(productDetails)}
                {productDetails?.genre ? (
                  <li>Genre : {productDetails?.genre}</li>
                ) : (
                  ""
                )}

                <li>Language : {productDetails?.language}</li>
                {productDetails?.no_of_page ? (
                  <li>No. of Pages : {productDetails?.no_of_page}</li>
                ) : (
                  ""
                )}
              </ul>
              <hr />
              {productDetails?.description && (
                <p className="text-justify">
                  <b>Description :</b>
                  <span className="descp"> {productDetails?.description}</span>
                </p>
              )}
            </div>
            <div className="col-md-3 ">
              <div className="shadow-p text-center rounded">
                <h3 className="bg-light-blue  p-3">₹{productDetails?.mrp}</h3>
                <div className="py-3">
                  <b className="text-redc">Format : </b>{" "}
                  {bookTypes[productDetails?.binding_type]}
                </div>
                <span className="badge badge-pill bg-info my-2">
                  ISBN : {productDetails.isbn}
                </span>
                <div className="d-flex justify-content-center py-4">
                  <button className="btn-quantity" onClick={quantityMinus}>
                    <FaMinus />
                  </button>
                  <p className="px-4 pt-2">
                    <b> {quantity} </b>
                  </p>
                  <button className="btn-quantity" onClick={quantityPlus}>
                    <FaPlus />
                  </button>
                </div>
                {productDetails?.is_active == 1 ? (
                  <div className="d-flex justify-content-center py-4">
                    <button
                      disabled={productDetails.stock_quantity < 1}
                      class={`button-87 ${
                        productDetails.stock_quantity < 1 && "btn-disabled"
                      }`}
                      onClick={goToProduct}
                    >
                      <BsHandbag className="me-2 fs-5" />
                      Add to Cart
                    </button>
                  </div>
                ) : (
                  <span style={{ fontSize: "20px", color: "red" }}>
                    Currently Unavailable
                  </span>
                )}

                <div className="py-4 share-s d-flex  justify-content-center">
                  {/* <Link to="/wishlist">
                  <AiOutlineHeart className="me-1" />
                  Add to Wishlist
                </Link> */}
                  {/* <ul class="menu bottomRight">
                    <li class="share top">
                      <Link to="#" className="ms-3 share top">
                        <AiOutlineShareAlt className="me-1" />
                        Share
                      </Link>
                      <ul class="submenu">
                        <li>
                          <a href="#" class="facebook">
                            <i class="fa fa-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#" class="twitter">
                            <i class="fa fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#" class="googlePlus">
                            <i class="fa fa-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#" class="whatsapp">
                            <i class="fa fa-whatsapp"></i>
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* product related list */}

      <section class="bg-light-blue py-5">
        <div class="container">
          <div className="d-flex align-items-baseline justify-content-between ">
            <h2 class="mb-5 heading-text mx-lg-auto">Related Products</h2>
          </div>
          {relatedProducts.length > 0 ? (
            <OwlCarousel
              className="owl-theme books"
              margin={10}
              autoplaySpeed={2000}
              autoplay={true}
              nav={true}
              items={6}
              responsiveClass={true}
              responsive={{
                0: { items: 2, nav: false },
                768: { items: 2, nav: false },
                990: { nav: false },
              }}
              dots={true}
              key={`carousel_ewewdweddwedwe`}
            >
              {relatedProducts.map((pl) => {
                return (
                  <div class="item" key={pl.id}>
                    <Link to={`/product/${pl.id}`}>
                      <div class="team-3">
                        <div class="member-thumb image-box position-relative">
                          <img
                            src={imageUrl + pl.image}
                            alt="team-3"
                            class="img-fluid "
                          />
                          {pl.is_active == 1 && pl.stock_quantity > 0 && (
                            <div className="member-socials cart-btn">
                              <Link
                                to="/cart"
                                class="text-center mb-4"
                                onClick={() =>
                                  addToCart({ ...pl, quantity: 1 })
                                }
                              >
                                <BsHandbag />
                              </Link>
                            </div>
                          )}
                        </div>
                        <div class="team-hover-content">
                          <div class="member-thumb">
                            <img
                              src={imageUrl + pl.image}
                              alt="team-3"
                              class="img-fluid"
                            />
                          </div>
                          <div class="member-name-designation"></div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </OwlCarousel>
          ) : (
            ""
          )}
        </div>
      </section>
    </>
  );
};
export default Product;
