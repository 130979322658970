import  {apis,request,methods} from "../request";
export const httpProduct = (page=1)=>{
    return request(methods.get,apis.getProducts+page,null,null,true)
}
 
export const httplanguagefilter=(body)=>{
    return request(methods.post,apis.languageByproduct_filter,body)
}

export const httpProductsearch=(body,page=1)=>{
    return request(methods.post,apis.searchByText+page,body,null,true)
}

export const httpProductsListForHome = () => {
    return request(methods.get, apis.homePageProducts);
}