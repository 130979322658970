import React from "react";
import Porder from "../assets/img/place-order.png";
import { Link, useParams } from "react-router-dom";

const PlaceOrder = () => {
  const { orderId } = useParams();
  
  return (
    <>
      <section className="bg-lightblue py-3 position-relative">
        <div className="container vertical-center">
          <div className="row justify-content-center">
            <div className="col-md-7">
              <div className="place-order text-center">
                <h2>Thank you for your order!</h2>
                <h3>{orderId}</h3>
                <p>
                  We're processing it now. Shortly you will receive a
                  confirmation email along with the content you ordered!
                </p>

                <div className="succ-img">
                  <img src={Porder} />
                </div>
              </div>
              <div className="d-flex justify-content-center mt-5 pt-4">
                <Link
                  to="/my-account"
                  className="button-87"
                  style={{ width: "30%" }}
                >
                  Go To My Account
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PlaceOrder;
