import axios from "axios";
import { baseUrl } from "./urls";
// function findKeyByValue(value) {
//   const key = Object.keys(apis).filter((key) => apis[key] === value)[0];
//   return key.split("_")[0];
// }

export const request = async (method, api, body = null, headers = null,save=false) => {
  // console.log({ method, api: baseUrl + api, body, headers });
  
  if (save) {
    localStorage.setItem(
      "currentApi",
      JSON.stringify({ method, api: baseUrl + api, body, headers })
    );
  }
  
  try {
    if (method === "get") {
      const res = await axios[method](
        baseUrl + api,
        headers !== null ? headers : ""
      );
      return { data: res.data, error: null };
    } else {
      const res = await axios[method](
        baseUrl + api,
        body,
        headers !== null ? headers : ""
      );
      return { data: res.data, error: null };
    }
  } catch (err) {
    console.log(err)
    return { data: null, error: err};
  }
};
