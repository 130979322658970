import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { httpCreateOrder, httpGetOrderStatus } from "../apimethod";
import { toast } from "react-toastify";
import { CartContext } from "../context/CartContext";
import processingImage from "../assets/img/payment.jpg";
import paymentFailedImage from "../assets/img/payment-failed.jpg";

const PaymentProcessing = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const shippingAddress = searchParams.get("shippingAddress");
  const order_id = searchParams.get("order_id");

  const token = localStorage.getItem("token");
  const { setLoader, refreshCartState } = useContext(CartContext);
  const navigate = useNavigate();

  const [image,setImage] = useState(processingImage)


  useEffect(() => {
      setLoader(true);
      httpGetOrderStatus(
        {
          shipping_address_id: shippingAddress,
          order_id: order_id,
        },
        token
      ).then((innerRes) => {
        if (!innerRes.data) {
          toast.error("create payment failed");
          setImage(paymentFailedImage)
          setLoader(false);
        } else {
          refreshCartState();
          setLoader(false);
          navigate(`/place-order/${innerRes.data.order_number}`, {
            replace: true,
          });
        }
      });
  }, []);

  return (
    <>
      <section className="bg-lightblue py-3 position-relative">
        <div className="container vertical-center text-center">
          <img src={image} alt="not load" style={{ width: "50%" }} />
          <br />
          {image == paymentFailedImage ?
            <Link to={"/checkout"}>
              <button className="btn btn-danger mt-2">Go to Checkout</button>
            </Link>
          :""}
        </div>
      </section>
    </>
  );
};
export default PaymentProcessing;
