import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { toast } from "react-toastify";

function PaginatedItems({ itemsPerPage, count, setProducts, setLoader }) {
  // console.log(`count ${count} itemsPerPage ${itemsPerPage}`);
  const pageCount = Math.ceil(count / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = async (event) => {
    const currentApi = JSON.parse(localStorage.getItem("currentApi"));
    const pageNumber = event.selected + 1;
    const uri = currentApi.api.split("=")[0] + "=" + pageNumber;
    setLoader(true);
    try {
      const res = await axios[currentApi.method](uri, currentApi.body);
      setProducts(res.data.data);
      setLoader(false);
      window.scrollTo(0,0)
    } catch (err) {
      toast.error("failed to load next page")
      setLoader(false);
    }
  };

  return (
    <>
      {/* <Items currentItems={currentItems} /> */}
      <ReactPaginate
        className="paginate-custom"
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={10}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
      />
    </>
  );
}

export default PaginatedItems;
