export const orderStatus = {
  1: (
    <td>
      <p className="pending mb-0 bg-warning">Pending</p>
    </td>
  ),

  2: (
    <td>
      <p className="completed mb-0 bg-success">Accepted</p>
    </td>
  ),

  3: (
    <td>
      <p className="completed mb-0 bg-success">Shipped</p>
    </td>
  ),

  4: (
    <td>
      <p className="cancel mb-0 bg-success">Delivered</p>
    </td>
  ),
  5: (
    <td>
      <p className="cancel mb-0 bg-danger">Cancelled</p>
    </td>
  ),
};
