import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/css/style.css";
import "../src/assets/css/responsive.css";
import Home from "./pages/home";
import Signin from "./pages/signin";
import Signup from "./pages/signup";
import Wrap from "./pages/wrap";
import Shop from "./pages/shop";
import Cart from "./pages/Cart";
import Product from "./pages/product";
import MyAccount from "./pages/myaccount";
import OrderSummary from "./pages/order-summary";
import Check_Out from "./pages/checkout";
import PlaceOrder from "./pages/place-order";
import CreateNewPassword from "./pages/create-new-password";
import Loader from "./components/loader";
import ForgotPassword from "./components/forgot-password";
import { ToastContainer } from "react-toastify";
import { useAuth, UserContext } from "./context/User";
import { CartContext, useCart } from "./context/CartContext";


import "react-toastify/dist/ReactToastify.css";
import Guard from "./utils/custom.guard";
import PaymentProcessing from "./pages/payment-processing";

function App() {
  const location = useLocation();
  const userContext= useAuth();
  const cart = useCart(userContext.isUserValid);

  useEffect(() => {
    userContext.refreshState();
    cart.refreshCartState();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);


  return (
    <CartContext.Provider value={{ ...cart }}>
      <UserContext.Provider value={{ ...userContext }}>
        <Loader loader={userContext.loader} />
        <Loader loader={cart.loader} />
        <ToastContainer />

        <Routes>
          {Guard(Signin, "/signin", { unAuthRequire: true })}
          {Guard(Signup, "/signup", { unAuthRequire: true })}
          {Guard(ForgotPassword, "/forgot-password", { unAuthRequire: true })}
          {Guard(CreateNewPassword, "/create-new-password", {
            unAuthRequire: true,
          })}

          {Guard(
            Wrap,
            "/my-account",
            { authRequire: true },
            { Component: MyAccount }
          )}
          {Guard(
            Wrap,
            "/checkout",
            { authRequire: true },
            { Component: Check_Out }
          )}

          {Guard(
            Wrap,
            "/order-summary/:order_id",
            { authRequire: true },
            { Component: OrderSummary }
          )}

          <Route element={<Wrap Component={Home} />} path="/" />
          <Route element={<Wrap Component={Shop} />} path="/shop/:data?" />
          <Route element={<Wrap Component={Cart} />} path="/cart" />
          <Route element={<Wrap Component={Product} />} path="/product/:id" />

          <Route
            element={<Wrap Component={PlaceOrder} />}
            on
            path="/place-order/:orderId"
          />
          <Route
            element={<Wrap Component={PaymentProcessing} />}
            path="/payment-processing"
          />
        </Routes>
      </UserContext.Provider>
    </CartContext.Provider>
  );
}

export default App;
