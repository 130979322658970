import React, { useEffect, useState, useContext } from "react";
import { HiStar } from "react-icons/hi";
import { BsHandbag, BsHeart } from "react-icons/bs";
import { BiSearch } from "react-icons/bi";
import { Link, useLocation, useParams } from "react-router-dom";
import { httpCategorybyproduct } from "../apimethod";
import {
  httpCategory,
  httpcategoryfilter,
  httpProductsearch,
  httpProduct,
} from "../apimethod";
import { httpLanguage } from "../apimethod";
import { CartContext } from "../context/CartContext";
import DataNotFound from "../utils/data-not-found";
import { toast } from "react-toastify";
import Pagination from "../components/pagination";
import { imageUrl } from "../request"
import {textWrapper} from "../utils/validations"

const Shop = () => {
  const { state } = useLocation();
  const { data } = useParams();
  const { addToCart, setLoader } = useContext(CartContext);

  const [categorybyProduct, setCategorybyProduct] = useState([]);
  const [categoryDetails, setCategoryDetails] = useState([]);
  const [languageDetails, setLanguageDetails] = useState([]);
  const [categoryidarray, setCategoryidArray] = useState([]);
  const [languageidarray, setLanguageidArray] = useState([]);
  const [textForSearch, setTextForSearch] = useState("");
  const [handleExtra, setHandleExtra] = useState(false);
  
  //total data count for paginate
  const [count,setCount] = useState(0)

  const fetchCategoryAndLanguage = () => {
    setLoader(true);
    httpCategory().then((res) => {
      if (!res.data) {
        setLoader(false);
        toast("category not fetched");
      } else {
        setLoader(false);
        setCategoryDetails(res.data.data);
      }
    });

    setLoader(true);
    httpLanguage().then((res) => {
      if (!res.data) {
        setLoader(false);
        toast("language not fetched");
      } else {
        setLoader(false);
        setLanguageDetails(res.data.data);
      }
    });
  };

  const fetchAllProducts = () => {
    setLoader(true);
    httpProduct().then((res) => {
      if (!res.data) {
        setLoader(false);
        toast.error("failed to fetch products");
      } else {
        setLoader(false);
        setCount(res.data.total)
        setCategorybyProduct(res.data.data);
      }
    });
  };

  const fetchProductsByText = (text) => {
    setLoader(true);
    httpProductsearch({ product_title: text }).then((res) => {
      if (!res.data) {
        setLoader(false);
        toast.error("products not fethced");
      } else {
        setLoader(false);
        setCount(res.data.total);
        setCategorybyProduct(res.data.data?.length ? res.data.data : []);
      }
    });
  };

  useEffect(() => {
    fetchCategoryAndLanguage();
  }, []);

  useEffect(() => {
    if (data) {
      fetchProductsByText(data);
    } else if (!state) {
      fetchAllProducts();
    }

    if (state && state.id) {
      httpCategorybyproduct(state.id).then((res) => {
        setCount(res.data.total);
        setCategorybyProduct(res.data.data?.length ? res.data.data : []);
      });
    }
  }, [data]);

  useEffect(() => {
    if (handleExtra) {
      setLoader(true);
      httpcategoryfilter({
        cat_id: categoryidarray.join(","),
        language_id: languageidarray.join(","),
      }).then((res) => {
        if (!res.data) {
          setLoader(false);
          toast.warn("failed to fetched products");
        } else {
          setLoader(false);
          setCount(res.data.total);
          setCategorybyProduct(res.data.data?.length ? res.data.data : []);
        }
      });
    }
  }, [languageidarray, categoryidarray]);

  const sideBarHandler = (e, array, method) => {
    setHandleExtra(true);
    if (e.target.checked) {
      array.push(e.target.value);
      method([...array]);
    } else {
      const index = array.indexOf(e.target.value);
      if (index > -1) {
        array.splice(index, 1);
        method([...array]);
      }
    }
  };

  const checkboxlanguage = (e) => {
    sideBarHandler(e, languageidarray, setLanguageidArray);
  };
  const checkboxhandeler = (e) => {
    sideBarHandler(e, categoryidarray, setCategoryidArray);
  };

  const onSearch = () => {
    fetchProductsByText(textForSearch);
  };

 


  return (
    <>
      <nav aria-label="breadcrumb" class="breadcrumbs large-font">
        <ol class="breadcrumb pt-2 ps-3">
          <li class="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Book List
          </li>
        </ol>
      </nav>
      <section className="py-5 bg-light-blue">
        <div className="container">
          <div className="row">
            <div className="col-md-3 mb-4">
              <div className="filter mb-2">
                <div className="filter-title">
                  <h5 className="text-redc">Genre</h5>
                  <div className="shop-search">
                    <input
                      className="shop-search-input"
                      type="text"
                      value={textForSearch}
                      onChange={(e) => setTextForSearch(e.target.value)}
                      placeholder="Search something.."
                    />
                    <button className="shop-search-btn" onClick={onSearch}>
                      <BiSearch />
                    </button>
                  </div>
                  <div className="shop-cat">
                    <ul className="list-unstyled mt-4 mb-0">
                      {categoryDetails.map((el) => {
                        return (
                          <li key={el.id}>
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                onChange={checkboxhandeler}
                                type="checkbox"
                                value={el.id}
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckDefault"
                              >
                                {el.title}
                              </label>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="filter">
                <div className="filter-title">
                  <h5 className="text-redc pb-2">More Filter</h5>
                </div>
                <div className="filter shop-cat mb-2">
                  <h6>Language</h6>
                  <ul className="list-unstyled mt-3 mb-0">
                    {languageDetails.map((ld) => {
                      return (
                        <li key={ld.id}>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              onChange={checkboxlanguage}
                              type="checkbox"
                              value={ld.id}
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault"
                            >
                              {ld.name}
                            </label>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                {/* <div className="filter shop-cat mb-2">
                  <h6>Binding</h6>
                  <ul className="list-unstyled mt-3 mb-0">
                    <li>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          Hardcover
                        </label>
                      </div>
                    </li>
                    <li>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          Paperback
                        </label>
                      </div>
                    </li>
                  </ul>
                </div> */}
                {/* <div className="filter shop-cat">
                  <h6>Other</h6>
                  <ul className="list-unstyled mt-3 mb-0">
                    <li>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          Best sellers
                        </label>
                      </div>
                    </li>
                    <li>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          Trending this week
                        </label>
                      </div>
                    </li>
                    <li>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          Upcoming Lauches
                        </label>
                      </div>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="col-md-9">
              <div className="row">
                {categorybyProduct.length > 0
                  ? categorybyProduct.map((cp) => {
                      return (
                        <div className="col-md-3" key={cp.id}>
                          
                          <Link to={`/product/${cp.id}`} state={cp}>
                            <div className="product-cart">
                              <div className="poduct-img position-relative">
                                <img
                                  src={imageUrl + cp.image}
                                  alt="team-3"
                                  class="img-fluid "
                                />
                                <div className="shop-btn text-center">
                                  {cp.is_active == 1 && cp.stock_quantity > 0 &&  (
                                    <div className="btnhover cart-btn">
                                      <Link
                                        to="/cart"
                                        onClick={() =>
                                          addToCart({ ...cp, quantity: 1 })
                                        }
                                        class="text-center mb-2"
                                      >
                                        <BsHandbag />
                                      </Link>
                                      <Link to="/cart" class="text-center">
                                        <BsHeart />
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="product-desc pt-1">
                                <h5 className="title mb-0">
                                  <a href="">{textWrapper(cp.book_title)}</a>
                                </h5>
                                <p className="author">
                                  <b>By :</b>
                                  {textWrapper(cp.author_name)}
                                </p>
                                <div className="book-footer d-flex justify-content-between">
                                  <div className="rate">
                                    <HiStar /> 4.2
                                  </div>
                                  <div className="price">₹ {cp.mrp}</div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })
                  : ""}

                {categorybyProduct && categorybyProduct.length > 0 ? (
                  <div className="col-md-12 text-center">
                    <Pagination
                      itemsPerPage={8}
                      count={count}
                      setProducts={setCategorybyProduct}
                      setLoader={setLoader}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="d-flex justify-content-center mt-5 pt-5 ">
                {categorybyProduct && categorybyProduct.length > 0 ? (
                  ""
                ) : (
                  <DataNotFound />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Shop;
