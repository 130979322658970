import React, { useState, useEffect, useContext } from "react";
import img2 from "../assets/img/banner/img-2.png";
import img1 from "../assets/img/banner/img-1.png";
import img3 from "../assets/img/banner/img-3.png";
import "animate.css";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import { BsHandbag } from "react-icons/bs";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
import { httpCategory, httpProductsListForHome } from "../apimethod";
import { imageUrl, categoryUrl } from "../request/urls";

import { CartContext } from "../context/CartContext";
import { UserContext } from "../context/User"
import { toast } from "react-toastify";

const Home = () => {
  const [categoryDetails, setCategoryDetails] = useState([]);
  const [productList, setProductList] = useState({
    new_release: [],
    most_popular: [],
    upcoming: [],
  });
  const { addToCart, setLoader } = useContext(CartContext);
  const { recentlyViewProducts } = useContext(UserContext);

  useEffect(() => {
    setLoader(true);
    httpCategory().then((res) => {
      if (!res.data) {
        setLoader(false);
        toast("category not fetched");
      } else {
        setLoader(false);
        setCategoryDetails(res.data.data);
      }
    });
    setLoader(true);
    httpProductsListForHome().then((res) => {
      if (!res.data) {
        setLoader(false);
        toast("product not fetched");
      } else {
        setLoader(false);
        console.log(res.data.data);
        setProductList(res.data.data);
      }
    });
  }, []);

  return (
    <>
      <section className="banner-wapper pt-lg-5">
        <Carousel controls={false}>
          <Carousel.Item>
            <div className="container">
              <div className="row align-items-stretch flex-wrap-reverse">
                <div className="col-md-7 align-self-center">
                  <div className="banner-content">
                    <h6 className="sub-title animate__animated animate__fadeInRightBig">
                      BFC BOOK STORE
                    </h6>
                    <h1 className="title mb-2 fading">Editors’ Picks</h1>
                    {/* <ul className="dz-tags ps-0 list-unstyled">
                      <li>
                        <a href="javascript:void(0);">Napoleon Hill</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          Business &amp; Strategy
                        </a>
                      </li>
                    </ul> */}
                    <p class="text mb-3 text-justify text-rale">
                      Dive into Curated Excellence with Our
                      Editors’ Handpicked Gems
                    </p>
                  </div>
                  <div className="banner-btn pt-4 d-flex">
                    <Link class="button-87" to="/shop">
                      Shop Now
                    </Link>
                  </div>
                </div>
                <div className="col-md-5 mb-3">
                  <img className="img-fluid" src={img1} alt="Second slide" />
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="container">
              <div className="row  align-items-stretch flex-wrap-reverse">
                <div className="col-md-7  align-self-center">
                  <div className="banner-content">
                    <h6 className="sub-title animate__animated animate__fadeInRightBig">
                      BFC BOOK STORE
                    </h6>
                    <h1 className="title mb-2 fading">Bestsellers</h1>
                    {/* <ul className="dz-tags ps-0 list-unstyled">
                      <li>
                        <a href="javascript:void(0);">Napoleon Hill</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          Business &amp; Strategy
                        </a>
                      </li>
                    </ul> */}
                    <p class="text mb-3 text-justify text-rale">
                      Explore the Books Topping Our Bestseller Charts!
                    </p>
                  </div>
                  <div className="banner-btn pt-4 d-flex">
                    <Link class="button-87" to="/shop">
                      Shop Now
                    </Link>
                  </div>
                </div>
                <div className="col-md-5 mb-3">
                  <img className="img-fluid" src={img2} alt="Second slide" />
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="container">
              <div className="row align-items-stretch flex-wrap-reverse">
                <div className="col-md-7 align-self-center">
                  <div className="banner-content">
                    <h6 className="sub-title animate__animated animate__fadeInRightBig">
                      BFC BOOK STORE
                    </h6>
                    <h1 className="title mb-2 fading">Best of March 2024</h1>
                    <ul className="dz-tags ps-0 list-unstyled">
                      {/* <li>
                        <a href="javascript:void(0);">Napoleon Hill</a>
                      </li> */}
                      {/* <li>
                        <a href="javascript:void(0);">
                          Business &amp; Strategy
                        </a>
                      </li> */}
                    </ul>
                    <p class="text mb-3 text-justify text-rale">
                      Delve into the Finest Reads of the Month,
                      Handpicked Just for You!
                    </p>
                  </div>
                  <div className="banner-btn pt-4 d-flex">
                    <Link class="button-87" to="/shop">
                      Shop Now
                    </Link>
                  </div>
                </div>
                <div className="col-md-5 mb-3">
                  <img className="img-fluid" src={img3} alt="Second slide" />
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </section>
      <section className="category py-5">
        <div className="container">
          <h2 class="mb-5 heading-text mx-lg-auto">Categories</h2>
          {categoryDetails.length > 0 ? (
            <OwlCarousel
              className="owl-theme books"
              margin={10}
              autoplaySpeed={2000}
              autoplay={true}
              items={4}
              responsiveClass={true}
              responsive={{
                0: { items: 1, nav: true },
                768: { items: 2, nav: true },
                990: { nav: true },
              }}
              dots={false}
              key={`carousel_ewdwedwe`}
            >
              {categoryDetails.map((el) => {
                return (
                  <div class="item" key={el.id}>
                    <Link to="/shop" state={el}>
                      <div className="card shadowc">
                        <div className="card-body">
                          <img
                            src={categoryUrl + el.url}
                            alt=""
                            className="img-fluid pb-4"
                          />
                          <h6 className="text-redc">{el.title}</h6>
                          <p className="text-rale">Best Collections</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </OwlCarousel>
          ) : (
            ""
          )}
        </div>
      </section>
      <section class="bg-light-blue py-5">
        <div class="container">
          <div className="d-flex align-items-baseline justify-content-between ">
            <h2 class="mb-5 heading-text mx-lg-auto">New Release</h2>
            {/* <Link class="view-btn" to="/shop">
              View More
              <span>
                <BsArrowRight className="viewbtn-icon" />
              </span>
            </Link> */}
          </div>
          {productList.new_release.length > 0 ? (
            <OwlCarousel
              className="owl-theme books"
              margin={10}
              autoplaySpeed={2000}
              autoplay={true}
              items={6}
              responsiveClass={true}
              responsive={{
                0: { items: 2, nav: false },
                768: { items: 2, nav: false },
                990: { nav: false },
              }}
              dots={true}
              key={`carousel_ewewdweddwedwe`}
            >
              {productList.new_release.map((pl) => {
                return (
                  <div class="item" key={pl.id}>
                    <Link to={`/product/${pl.id}`}>
                      <div class="team-3">
                        <div class="member-thumb image-box position-relative">
                          <img
                            src={imageUrl + pl.image}
                            alt="team-3"
                            class="img-fluid "
                          />
                          {pl.is_active == 1 && pl.stock_quantity > 0 && (
                            <div className="member-socials cart-btn">
                              <Link
                                to="/cart"
                                class="text-center mb-4"
                                onClick={() =>
                                  addToCart({ ...pl, quantity: 1 })
                                }
                              >
                                <BsHandbag />
                              </Link>
                              {/* <Link to="/cart" class="text-center">
                                <BsHeart />
                              </Link> */}
                            </div>
                          )}
                        </div>
                        <div class="team-hover-content">
                          <div class="member-thumb">
                            <img
                              src={imageUrl + pl.image}
                              alt="team-3"
                              class="img-fluid"
                            />
                          </div>
                          <div class="member-name-designation"></div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </OwlCarousel>
          ) : (
            ""
          )}
        </div>
      </section>

      {/* recent views products */}
      {recentlyViewProducts.length > 0 && (
        <section class="bg-light-blue py-5">
          <div class="container">
            <div className="d-flex align-items-baseline justify-content-between ">
              <h2 class="mb-5 heading-text mx-lg-auto">Recent Views</h2>
            </div>
            <OwlCarousel
              className="owl-theme books"
              margin={10}
              autoplaySpeed={2000}
              autoplay={true}
              items={6}
              responsiveClass={true}
              responsive={{
                0: { items: 2, nav: false },
                768: { items: 2, nav: false },
                990: { nav: false },
              }}
              dots={true}
              key={`carousel_ewewdweddwedwe`}
            >
              {recentlyViewProducts.map((pl) => {
                return (
                  <div class="item" key={pl.id}>
                    <Link to={`/product/${pl.id}`}>
                      <div class="team-3">
                        <div class="member-thumb image-box position-relative">
                          <img
                            src={imageUrl + pl.image}
                            alt="team-3"
                            class="img-fluid "
                          />
                          {pl.is_active == 1 && pl.stock_quantity > 0 && (
                            <div className="member-socials cart-btn">
                              <Link
                                to="/cart"
                                class="text-center mb-4"
                                onClick={() =>
                                  addToCart({ ...pl, quantity: 1 })
                                }
                              >
                                <BsHandbag />
                              </Link>
                              {/* <Link to="/cart" class="text-center">
                                <BsHeart />
                              </Link> */}
                            </div>
                          )}
                        </div>
                        <div class="team-hover-content">
                          <div class="member-thumb">
                            <img
                              src={imageUrl + pl.image}
                              alt="team-3"
                              class="img-fluid"
                            />
                          </div>
                          <div class="member-name-designation"></div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </OwlCarousel>
          </div>
        </section>
      )}

      {/* recent views prodducts end */}

      <section class=" py-5">
        <div class="container">
          <div className="d-flex align-items-baseline justify-content-between">
            <h2 class="mb-5 heading-text mx-lg-auto">Most Popular</h2>
            {/* <Link class="view-btn" to="/shop">
              View More
              <span>
                <BsArrowRight className="viewbtn-icon" />
              </span>
            </Link> */}
          </div>
          {productList.most_popular.length > 0 ? (
            <OwlCarousel
              className="owl-theme books"
              margin={10}
              autoplaySpeed={2000}
              autoplay={true}
              items={6}
              responsiveClass={true}
              responsive={{
                0: { items: 2, nav: false },
                768: { items: 2, nav: false },
                990: { nav: false },
              }}
              dots={true}
            >
              {productList.most_popular.map((pl) => {
                return (
                  <div class="item" key={pl.id}>
                    <Link to={`/product/${pl.id}`}>
                      <div class="team-3">
                        <div class="member-thumb image-box position-relative">
                          <img
                            src={imageUrl + pl.image}
                            alt="team-3"
                            class="img-fluid "
                          />
                          {pl.is_active == 1 && pl.stock_quantity > 0 && (
                            <div className="member-socials cart-btn">
                              <Link
                                to="/cart"
                                class="text-center mb-4"
                                onClick={() =>
                                  addToCart({ ...pl, quantity: 1 })
                                }
                              >
                                <BsHandbag />
                              </Link>
                              {/* <Link to="/cart" class="text-center">
                                <BsHeart />
                              </Link> */}
                            </div>
                          )}
                        </div>
                        <div class="team-hover-content">
                          <div class="member-thumb">
                            <img
                              src={imageUrl + pl.image}
                              alt="team-3"
                              class="img-fluid"
                            />
                          </div>
                          <div class="member-name-designation"></div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </OwlCarousel>
          ) : (
            ""
          )}
        </div>
      </section>
      <section class="upcoming-book bg-light-blue py-5">
        <div class="container">
          <div className="d-flex align-items-baseline justify-content-between">
            <h2 class="mb-5 heading-text mx-lg-auto">Upcoming Launches</h2>
            {/* <Link class="view-btn" to="/shop">
              View More
              <span>
                <BsArrowRight className="viewbtn-icon" />
              </span>
            </Link> */}
          </div>
          {productList.upcoming.length > 0 ? (
            <OwlCarousel
              className="owl-theme books"
              margin={10}
              autoplaySpeed={2000}
              autoplay={true}
              items={6}
              responsiveClass={true}
              responsive={{
                0: { items: 2, nav: false },
                768: { items: 2, nav: false },
                990: { nav: false },
              }}
              dots={true}
            >
              {productList.upcoming.reverse().map((pl) => {
                return (
                  <div class="item" key={pl.id}>
                    <Link to={`/product/${pl.id}`}>
                      <div class="team-3">
                        <div class="member-thumb image-box position-relative">
                          <img
                            src={imageUrl + pl.image}
                            alt="team-3"
                            class="img-fluid "
                          />
                          {pl.is_active == 1 && pl.stock_quantity > 0 && (
                            <div className="member-socials cart-btn">
                              <Link
                                to="/cart"
                                class="text-center mb-4"
                                onClick={() =>
                                  addToCart({ ...pl, quantity: 1 })
                                }
                              >
                                <BsHandbag />
                              </Link>
                              {/* <Link to="/cart" class="text-center">
                                <BsHeart />
                              </Link> */}
                            </div>
                          )}
                        </div>
                        <div class="team-hover-content">
                          <div class="member-thumb">
                            <img
                              src={imageUrl + pl.image}
                              alt="team-3"
                              class="img-fluid"
                            />
                          </div>
                          <div class="member-name-designation"></div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </OwlCarousel>
          ) : (
            ""
          )}
        </div>
      </section>
    </>
  );
};
export default Home;
