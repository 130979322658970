import React, { useState, useContext } from "react";
import signin from "../assets/img/others/signup.png";
import logo from "../assets/img/logo/bfc-logo-removebg-preview.png";
import { GiArchiveRegister } from "react-icons/gi";
import { Link } from "react-router-dom";
import { UserContext } from "../context/User";
import { CartContext } from "../context/CartContext";
import { isName, isEmail, isMobileNo, isPassword } from "../utils/validations";

const Signup = () => {
  // value state define//
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileno, setMobileno] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [termCon, setTermCon] = useState("");

  // error  state define//
  const [errorName, setErrorName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorMobileno, setErrorMobileno] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");
  const [checkBoxError, setCheckBoxError] = useState("");
  //user context

  const { signUp } = useContext(UserContext);
  const { fetchExistingCart } = useContext(CartContext);

  const checkValidation = () => {
    let hasError = false;

    // name validation

    if (name == "") {
      setErrorName("Mandatory Name");
      hasError = true;
    } else if (!isName(name)) {
      setErrorName("Invalid Name");
      hasError = true;
    } else {
      setErrorName("");
    }

    //email validation

    if (email == "") {
      setErrorEmail("Mandatory Email");
      hasError = true;
    } else if (!isEmail(email)) {
      setErrorEmail("Invalid Email");
      hasError = true;
    } else {
      setErrorEmail("");
    }

    //mobile number validation

    if (mobileno == "") {
      setErrorMobileno("Mandatory Mobile Number");
      hasError = true;
    } else if (!isMobileNo(mobileno)) {
      setErrorMobileno("Invalid Mobile Number");
      hasError = true;
    } else {
      setErrorMobileno("");
    }

    //password validation

    if (password == "") {
      setErrorPassword("Mandatory Password");
      hasError = true;
    } else if (!isPassword(password)) {
      setErrorPassword("Please enter valid password");
      hasError = true;
    } else {
      setErrorPassword("");
    }

    //confirm password validations

    if (confirmPassword == "") {
      setErrorConfirmPassword("Mandatory Confirm Password");
      hasError = true;
    } else if (password != confirmPassword) {
      setErrorConfirmPassword("Confirm Password Not Matched");
      hasError = true;
    } else {
      setErrorConfirmPassword("");
    }

    if (!termCon) {
      setCheckBoxError("Checkbox required");
      hasError = true;
    } else {
      setCheckBoxError("");
    }

    //check all errors
    return hasError;
  };

  const userRegister = async (e) => {
    e.preventDefault();

    if (checkValidation()) {
      return;
    }
    signUp({ name, email, mobile: mobileno, password }).then((res) => {
      if (res) {
        fetchExistingCart(res);
      }
    });
  };

  return (
    <>
      <section className=" login-bg pt-3">
        <div className="container">
          <div className="brand-logo ">
            <a href="/">
              <img src={logo} alt="" srcset="" className="img-fluid" />
            </a>
          </div>
          <div className="row align-items-stretch pt-3">
            <div className="col-md-7 ">
              <img src={signin} alt="" srcset="" className="img-fluid" />
            </div>
            <div className="col-md-5 align-self-center">
              <h4 className="pb-2 pt-1 text-redc ">Take a First Step</h4>
              <form>
                <div className="form-group mb-3">
                  <input
                    class="search-input si"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    name="name"
                    placeholder="Enter Your Full Name"
                  />
                  <span style={{ color: "red" }}>{errorName}</span>
                </div>
                <div className="form-group mb-3">
                  <input
                    class="search-input si"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    name="email"
                    placeholder="Enter Your Email"
                  />
                  <span style={{ color: "red" }}>{errorEmail}</span>
                </div>
                <div className="form-group mb-3">
                  <input
                    class="search-input si"
                    type="text"
                    value={mobileno}
                    onChange={(e) => setMobileno(e.target.value)}
                    name="mobileno"
                    placeholder="Enter Your Mobile Number"
                  />
                  <span style={{ color: "red" }}>{errorMobileno}</span>
                </div>
                <div className="form-group mb-3">
                  <input
                    class="search-input si"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    name="password"
                    placeholder="Enter Your Password"
                  />
                  <span style={{ color: "red" }}>{errorPassword}</span>
                </div>
                <div className="form-group mb-3">
                  <input
                    class="search-input si"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    name="confirmpassword"
                    placeholder="Confirm Password"
                  />
                  <span style={{ color: "red" }}>{errorConfirmPassword}</span>
                </div>

                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    // id="flexCheckDefault"
                    value={termCon}
                    onChange={(e) => setTermCon(e.target.checked)}
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    I, hereby accord my consent to process my above mentioned
                    personal data by BFC Publications Pvt. Ltd. for the purpose
                    of Author support/Service Promotion, in accordance with the
                    provisions of DPDP Act 2023
                  </label>
                <span> To know more <a href="https://bfcpublications.com/DPDP_Act" target="_blank" > click here</a></span>
                </div>
                <span style={{ color: "red" }}>{checkBoxError}</span>

                <div className="form-group mt-4">
                  <button
                    type="submit"
                    class="button-86 fw-bold"
                    onClick={userRegister}
                    role="button"
                  >
                    Register <GiArchiveRegister className="ms-2 fs-5" />
                  </button>
                </div>
                <hr />
                <p className="fs-14 text-rale">
                  Already have an account?{" "}
                  <Link to="/signin" className="login-a">
                    <b>Sign-In</b>
                  </Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Signup;
