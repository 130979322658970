import { useState, createContext } from "react";
import { httpSignin, httpSignup, httpGetProfile } from "../apimethod";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const UserContext = createContext(null);

const useAuth = () => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [loader, setLoader] = useState(false);
  const [recentlyViewProducts, setRecentlyViewProducts] = useState([]);
  const [timeSnapshot, setTimeSnapshot] = useState(null);

  const navigate = useNavigate();

  const request = async (method, body, path) => {
    setLoader(true);
    const res = await method(body);
    console.log(res)
    setLoader(false);
    if (!res.data) {
      // console.log(res.error)
      toast.error(res.error?.response?.data?.error);
      return null;
    } else {
     
      autoLogout(res.data.user.expires * 1000);
      res.data.user.expires = new Date(
        new Date().getTime() + res.data.user.expires * 1000
      );
      console.log(res.data)
      localStorage.setItem("token", res.data.access_token);
      localStorage.setItem("user", JSON.stringify(res.data.user));
      setUser(res.data.user);
      setToken(res.data.access_token);
      navigate(path);
      console.log("path"+path)
      return res.data.access_token;
    }
  };

  const signIn = async (body, path = "/") => {
    return request(httpSignin, body, path);
  };

  const signUp = (body, path = "/") => {
    return request(httpSignup, body, path);
  };

  const logout = () => {
    localStorage.clear();
    setUser(null);
    setToken(null);
    navigate("/signin");
    clearInterval(timeSnapshot);
  };

  const autoLogout = (expires) => {
    console.log("expires => "+ expires)
    const time = setTimeout(() => {
      logout();
    }, expires);
    setTimeSnapshot(time)
  };

  const isUserValid = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      if (new Date() > new Date(user.expires)) {
        return false;
      } else {
        return true;
      }
    }    
  };

  const refreshState = () => {
    const token = localStorage.getItem("token");
    if (token && isUserValid()) {
      const user = JSON.parse(localStorage.getItem("user"));
      autoLogout(new Date(user.expires).getTime() - new Date().getTime());
      setUser(user);
      setToken(token);
    }
  };

  const setUserProfile = async () => {
    setLoader(true);
    const res = await httpGetProfile(user.id, token);
    if (!res.data) {
      toast("User details not fetched");
      setLoader(false);
      return false;
    } else {
      setLoader(false);
      setUser(res.data.data[0]);
      localStorage.setItem("user", JSON.stringify(res.data.data[0]));
      return true;
    }
  };

  const isExistOnRecentView = (id) => {
    return recentlyViewProducts.find((el) => el.id == id);
  };

  const addRecentViewProduct = (product) => {
    const existingView = isExistOnRecentView(product.id);
    if (!existingView && recentlyViewProducts.length < 6) {
      recentlyViewProducts.push(product);
      setRecentlyViewProducts([...recentlyViewProducts]);
    } else if (!existingView && recentlyViewProducts.length == 6) {
      const tempArray = [];
      for (let i = 1; i < recentlyViewProducts.length; i++) {
        tempArray.push(recentlyViewProducts[i]);
      }
      tempArray.push(product);
      setRecentlyViewProducts(tempArray);
    } else if (existingView) {
      const filteredProdcuts = recentlyViewProducts.filter(
        (el) => el.id != existingView.id
      );
      filteredProdcuts.push(product);
      setRecentlyViewProducts(filteredProdcuts);
    }
  };

  return {
    user,
    signIn,
    signUp,
    logout,
    loader,
    setLoader,
    token,
    refreshState,
    setUserProfile,
    addRecentViewProduct,
    recentlyViewProducts,
    isUserValid,
  };
};

export { useAuth };
