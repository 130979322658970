import {useEffect,useState} from "react"
import Form from "react-bootstrap/Form";
import { FaPen } from "react-icons/fa";
import { isEmail,isMobileNo,isName} from "../utils/validations"
import {httpUpdateProfile} from "../apimethod"
import { toast } from "react-toastify";

function ProfileChange({ setLoader, token, setUserProfile }) {
  //user states
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");

  //error states
  const [errorName, setErrorName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorMobileNo, setErrorMobileNo] = useState("");
  const [errorImage, setErrorImage] = useState("");

  //toggle state
  const [userDisplay, setUserDisplay] = useState(true);

  //image

  const [image, setImage] = useState("");

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setName(user?.name);
    setEmail(user?.email);
    setMobileNo(user?.mobile);
  }, []);

  const saveProfile =  (e) => {
    e.preventDefault();
    let isError = false;

    if (!email) {
      isError = true;
      setErrorEmail("Email is mandatory");
    } else if (!isEmail(email)) {
      isError = true;
      setErrorEmail("Invalid email");
    } else {
      setErrorEmail("");
    }

    if (!mobileNo) {
      isError = true;
      setErrorMobileNo("MobileNo is mandatory");
    } else if (!isMobileNo(mobileNo)) {
      isError = true;
      setErrorMobileNo("Invalid MobileNo");
    } else {
      setErrorMobileNo("");
    }

    if (!name) {
      isError = true;
      setErrorName("Name is mandatory");
    } else if (!isName(name)) {
      isError = true;
      setErrorName("Invalid Name");
    } else {
      setErrorName("");
    }

    // if (!image) {
    //   isError = true;
    //   setErrorImage("Image is mandatory");
    // } else {
    //   setErrorImage("");
    // }

    if (!isError) {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("mobile", mobileNo);
      formData.append("image", image);

      setLoader(true);
      httpUpdateProfile(formData, token).then(async (res) => {
        if (!res.data) {
          setLoader(false);
          toast(res.error?.response?.data?.errors);
        } else {
          setLoader(false);
          const isSet = await setUserProfile()
          console.log(isSet)
          if (isSet) {
            setUserDisplay(true)
          }
        }
      });
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-6 align-self-center">
          <h4>BASIC INFORMATION</h4>
        </div>
        {userDisplay ? (
          <div className="col-6 text-end">
            <Form.Group className="" controlId="p4">
              <button
                class="button-87 float-end"
                role="button"
                onClick={() => setUserDisplay(false)}
              >
                <FaPen></FaPen>
              </button>
            </Form.Group>
          </div>
        ) : (
          ""
        )}
      </div>

      <hr />
      <Form>
        <div className="row ">
          <div className="col-md-6">
            <Form.Group className="mb-3" controlId="p1">
              <Form.Label className="color-span">Name </Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={name}
                onChange={(e) => setName(e.target.value)}
                disabled={userDisplay}
              />
              <span style={{ color: "red" }}>{errorName}</span>
            </Form.Group>
          </div>

          <div className="col-md-6">
            <Form.Group className="mb-3" controlId="p3">
              <Form.Label className="color-span">Email Id</Form.Label>
              <Form.Control
                type="email"
                placeholder="info@example.com"
                value={email}
                disabled={userDisplay}
                onChange={(e) => setEmail(e.target.value)}
              />
              <span style={{ color: "red" }}>{errorEmail}</span>
            </Form.Group>
          </div>
          <div className="col-md-6">
            <Form.Group className="mb-3" controlId="p4">
              <Form.Label className="color-span">Mobile Number</Form.Label>
              <Form.Control
                type="text"
                placeholder=" +91"
                value={mobileNo}
                disabled={userDisplay}
                onChange={(e) => setMobileNo(e.target.value)}
              />
              <span style={{ color: "red" }}>{errorMobileNo}</span>
            </Form.Group>
          </div>
          {!userDisplay ? (
            <div className="col-md-6">
              <Form.Group className="mb-3" controlId="p6">
                <Form.Label className="color-span">Profile Picture</Form.Label>
                <Form.Control
                  type="file"
                  placeholder=" +91"
                  onChange={(e) => setImage(e.target.files[0])}
                />
                <span style={{ color: "red" }}>{errorImage}</span>
              </Form.Group>
            </div>
          ) : (
            ""
          )}
        </div>

        {!userDisplay ? (
          <Form.Group className="mb-3" controlId="p4">
            <button class="button-87" role="button" onClick={saveProfile}>
              Save changes
            </button>
          </Form.Group>
        ) : (
          ""
        )}
      </Form>
    </>
  );
}

export default ProfileChange;