import React, { useState, useEffect, useContext } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { RiLockPasswordLine } from "react-icons/ri";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { FaUser, FaAddressCard, FaExchangeAlt } from "react-icons/fa";
import UserDefault from "../assets/img/others/profile.png";
import { CgLogOff } from "react-icons/cg";
import { Link } from "react-router-dom";
import {
  httpOrderList,
  httpGetShippingAddress,
  httpTransactions,
} from "../apimethod";
import { UserContext } from "../context/User";
import { CartContext } from "../context/CartContext";
import { toast } from "react-toastify";
import ProfileChange from "../components/profile-change";
import PasswordChange from "../components/change-password";
import { profileImageUrl } from "../request";
import Transactions from "../components/transaction";
import Order from "../components/order";
import Address from "../components/address";
import { orderStatus } from "../utils/order-status";

const MyAccount = () => {
  const token = localStorage.getItem("token");
  const { setLoader, logout, setUserProfile, user } = useContext(UserContext);
  const { clearCart } = useContext(CartContext);

  //order listing state
  const [orderList, setOrderList] = useState([]);

  // address list
  const [addressList, setAddressList] = useState([]);

  const [transactionList, setTranstactionList] = useState([]);

  useEffect(() => {
    setLoader(true);
    httpGetShippingAddress(token).then((res) => {
      if (!res.data) {
        setLoader(false);
        toast.error("address not fetched");
      } else {
        setLoader(false);
        setAddressList(res.data.data);
      }
    });
  }, []);

  useEffect(() => {
    setLoader(true);
    httpOrderList(token).then((res) => {
      if (!res.data) {
        toast.error("order list not fetched");
        setLoader(false);
      } else {
        setLoader(false);
        setOrderList(res.data.data.reverse());
      }
    });
  }, []);

  useEffect(() => {
    setLoader(true);
    httpTransactions(token).then((res) => {
      if (!res.data) {
        toast.error("failed to fetch transactions");
        setLoader(false);
      } else {
        setLoader(false);
        setTranstactionList(res.data.data);
      }
    });
  }, []);

  const deleteCartAndLogout = () => {
    clearCart();
    logout();
  };

  const createTime = (date) => {
    const time = new Date(date);
    return time.toLocaleTimeString();
  };

  const image = user?.profile_image
    ? profileImageUrl + user.profile_image
    : UserDefault;

  return (
    <>
      <nav aria-label="breadcrumb" class="breadcrumbs large-font">
        <ol class="breadcrumb pt-2 ps-3">
          <li class="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            My Account
          </li>
        </ol>
      </nav>
      <div className="account-main">
        <div className="container">
          {/* =================Tabs================ */}
          <Tabs>
            <div className="row mt-4 justify-content-around align-items-stretch">
              <div className="col-md-3">
                <div className="account-detail text-center">
                  <div className=" my-image">
                    <img src={image} alt="" className="img-fluid" />
                  </div>
                  <h5 className="mt-3">{user?.name}</h5>
                  {/* <p>Author</p> */}
                </div>
                <TabList className="d-grid ps-0 account-list">
                  <Tab>
                    <div className="d-flex align-items-baseline">
                      <span className="pe-3 fs-30">
                        <FaUser className="iconfa" />
                      </span>
                      <span>Profile</span>
                    </div>
                  </Tab>
                  <Tab>
                    <div className="d-flex align-items-baseline">
                      <span className="pe-3 fs-30">
                        <AiOutlineShoppingCart className="iconfa" />
                      </span>
                      <span>My Order</span>
                    </div>
                  </Tab>
                  <Tab>
                    <div className="d-flex align-items-baseline">
                      <span className="pe-3 fs-30">
                        <FaExchangeAlt className="iconfa" />
                      </span>
                      <span>Transaction</span>
                    </div>
                  </Tab>
                  <Tab>
                    <div className="d-flex align-items-baseline">
                      <span className="pe-3 fs-30">
                        <FaAddressCard className="iconfa" />
                      </span>
                      <span>Shipping Address</span>
                    </div>
                  </Tab>
                  <Tab>
                    <div className="d-flex align-items-baseline">
                      <span className="pe-3 fs-30">
                        <RiLockPasswordLine className="iconfa" />
                      </span>
                      <span>Change Password</span>
                    </div>
                  </Tab>
                  <div className="logout">
                    <div className="d-flex align-items-baseline">
                      <div onClick={deleteCartAndLogout}>
                        <span className="pe-3 fs-30">
                          <CgLogOff className="iconfa" />
                        </span>
                        <span className="text-black">Logout</span>
                      </div>
                    </div>
                  </div>
                </TabList>
              </div>
              {/* =================Tabs end================ */}
              <div className="col-md-8 detail-info ">
                {/* =================BASIC INFORMATION================ */}
                <TabPanel>
                  <ProfileChange
                    setLoader={setLoader}
                    token={token}
                    setUserProfile={setUserProfile}
                  />
                </TabPanel>
                {/* =================BASIC INFORMATION End================ */}
                {/* =================Order List================ */}
                <TabPanel>
                  <Order
                    orderList={orderList}
                    createTime={createTime}
                    orderStatus={orderStatus}
                  />
                </TabPanel>
                {/* =================Order List End================ */}
                {/* =================TRANSACTIONS================ */}
                <TabPanel>
                  <Transactions
                    transactionList={transactionList}
                    orderStatus={orderStatus}
                  />
                </TabPanel>
                {/* =================TRANSACTIONS End================ */}
                {/* =================ADDRESS================ */}
                <TabPanel>
                  <Address addressList={addressList} />
                </TabPanel>
                {/* =================ADDRESS End================ */}
                {/* =================CHANGE PASSWORD================ */}
                <TabPanel>
                  <PasswordChange
                    setLoader={setLoader}
                  />
                </TabPanel>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </>
  );
};
export default MyAccount;
