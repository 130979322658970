import { useState, createContext } from "react";
import {
  deleteProductFromCart,
  fetchCart,
  saveCart,
  updateCartItems,
} from "../apimethod";
import { toast as myToast } from "react-toastify";

function toast(message) {
  myToast(message, {
    toastId: "randomid",
  });
}

export const CartContext = createContext(null);

let cartToken = null;
const header = {
  headers: { Authorization: null },
};

export function useCart(isUserValid) {
  const [cart, setCart] = useState(null);
  const [loader, setLoader] = useState(false);

  const addToCart = async (product) => {
    if (!isProductOnCart(product)) {
      toast("Quantity is too much");
      return false;
    }

    if (isUserValid()) {
      const res = await saveCart(
        {
          products: [{ product_id: product.id, quantity: product.quantity }],
        },
        header
      );
      if (!res.data) {
        console.log(res.error);
      } else {
        refreshCartState();
      }
      return;
    }

    const existingCart = JSON.parse(localStorage.getItem("cart"));
    if (!existingCart) {
      const newCart = [];
      newCart.push(product);
      localStorage.setItem("cart", JSON.stringify(newCart));
      setCart(newCart);
    } else {
      const duplicate = existingCart.find((el) => el.id === product.id);
      if (duplicate) {
        const quantity =
          parseInt(duplicate.quantity) + parseInt(product.quantity);
        duplicate.quantity = quantity;
        localStorage.setItem("cart", JSON.stringify(existingCart));
        setCart(existingCart);
        return;
      }

      existingCart.push(product);
      localStorage.setItem("cart", JSON.stringify(existingCart));
      setCart(existingCart);
    }
  };

  const refreshCartState = () => {
    cartToken = localStorage.getItem("token");
    if (isUserValid()) {
      header.headers.Authorization = `Bearer ${cartToken}`;
      fetchCart(header).then(async (res) => {
        setCart(res.data.data);
      });
      return;
    }
    setCart(JSON.parse(localStorage.getItem("cart")));
  };

  const removeFromCart = async (id) => {
    if (isUserValid()) {
      try {
        const res = await deleteProductFromCart({ productId: id }, header);
        if (!res.data) {
          console.log(res.error);
        } else {
          const filterCart = cart.filter((el) => el.id !== id);
          if (filterCart.length == 0) {
            setCart(null);
            return;
          }
          setCart(filterCart);
        }
        return;
      } catch (err) {
        console.log(err);
      }
    }

    const filterCart = cart.filter((el) => el.id !== id);
    if (filterCart.length == 0) {
      localStorage.setItem("cart", JSON.stringify(null));
      setCart(null);
      return;
    }
    localStorage.setItem("cart", JSON.stringify(filterCart));
    setCart(filterCart);
  };

  const incrementByOne = async (product, num) => {
    let quantity = parseInt(product.quantity) + num;
    if (quantity < 1) {
      quantity = 1;
    }

    if (quantity > product.stock_quantity) {
      toast("Quantity To Much");
      return;
    }
    product.quantity = quantity;

    if (isUserValid()) {
      setLoader(true);
      const res = await updateCartItems(
        { products: [{ product_id: product.id, quantity: product.quantity }] },
        header
      );
      if (!res.data) {
        setLoader(false);
      } else {
        refreshCartState();
        setLoader(false);
      }
    } else {
      setCart([...cart]);
      localStorage.setItem("cart", JSON.stringify(cart));
    }
  };

  // const saveChanges = async () => {
  //   if (cartToken) {
  //     const allProducts = cart.map((el) => {
  //       return { product_id: el.id, quantity: el.quantity };
  //     });
  //     setLoader(true);
  //     const res = await updateCartItems({ products: allProducts }, header);
  //     if (!res.data) {
  //       console.log(res.error);
  //       setLoader(false);
  //     } else {
  //       setLoader(false);
  //     }
  //   }
  //   localStorage.setItem("cart", JSON.stringify(cart));
  // };

  const fetchExistingCart = (token) => {
    cartToken = token;
    header.headers.Authorization = `Bearer ${token}`;

    fetchCart(header).then(async (res) => {
      if (cart && cart.length > 0) {
        const allProducts = cart.map((el) => {
          return { product_id: el.id, quantity: el.quantity };
        });

        const saveResponse = await saveCart({ products: allProducts }, header);
        if (!saveResponse.data) {
          console.log(saveResponse.error);
        } else {
          localStorage.removeItem("cart");
          refreshCartState();
        }
      } else {
        setCart(res.data.data);
      }
    });
  };

  const clearCart = () => {
    setCart(null);
    cartToken = null;
  };

  const isProductOnCart = (product) => {
    // if (!cartToken) {
    //   const existingCart = JSON.parse(localStorage.getItem("cart"));
    //   if (!existingCart) {
    //     return {quantity:1,addable:true};
    //   }
    //   const product = existingCart.find((el) => el.id == displayProduct.id);
    //   if (!product) {
    //     return { quantity: 1, addable: true };
    //   }

    //   let isAddable = false;

    //   if (product.quantity < product.stock_quantity ) {
    //     isAddable = true;
    //   }

    //   return { quantity: product.quantity, addable: isAddable };
    // } else {

    if (cart) {
      const resultProduct = cart.find((element) => {
        return element.id === product.id;
      });

      if (!resultProduct) {
        if (product.quantity <= product.stock_quantity) {
          return true;
        }
        return false;
      }

      const someOfQuantity = resultProduct.quantity + product.quantity;

      console.log("some of quantity => " + someOfQuantity);
      console.log("stock quantity =>" + resultProduct.stock_quantity);

      if (someOfQuantity <= resultProduct.stock_quantity) {
        return true;
      } else {
        return false;
      }
    } else {
      if (product.quantity <= product.stock_quantity) {
        return true;
      }
      return false;
    }
  };

  const isAnyStockEmpty = () => {
    let isEmpty = false;
    if (cart) {
      for (let i = 0; i < cart.length; i++) {
        if (cart[i].stock_quantity < 1) {
          isEmpty = true;
          break;
        }
      }
    }

    return isEmpty;
  };

  return {
    cart,
    addToCart,
    refreshCartState,
    removeFromCart,
    incrementByOne,
    loader,
    setLoader,
    fetchExistingCart,
    clearCart,
    isAnyStockEmpty,
  };
}
