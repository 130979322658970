import { apis, request, methods } from "../request";

const header = {
  headers: { Authorization: null },
};

export const httpCreateOrder = (body,token) => {
  header.headers.Authorization = `Bearer ${token}`;
  return request(methods.post,apis.createOrder,body,header)
}

export const httpCreateOrderOnline = (body, token) => {
  header.headers.Authorization = `Bearer ${token}`;
  return request(methods.post, apis.createOrderOnline, body, header);
}

export const httpGetOrderStatus = (body, token) => {
  header.headers.Authorization = `Bearer ${token}`;
  return request(methods.post, apis.getPaymentStatus, body, header);
};


export const httpOrderList = (token) => {
  header.headers.Authorization = `Bearer ${token}`;
  return request(methods.get, apis.orderList, null, header);
}

export const httpOrderDetails = (body,token) => { 
    header.headers.Authorization = `Bearer ${token}`;
    return request(methods.post, apis.orderDetails, body, header);
}

export const httpTransactions = (token) => {
  header.headers.Authorization = `Bearer ${token}`;
  return request(methods.get, apis.getTransaction,null,header);
}

export const httpCancelOrder = (body,token) => {
  header.headers.Authorization = `Bearer ${token}`;
  return request(methods.post, apis.cancelOrder, body, header);
}