import React, { useState, useContext, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BiPencil, BiTrash } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { isMobileNo, isName } from "../utils/validations";
import { CartContext } from "../context/CartContext";
import { imageUrl } from "../request/urls";
import EmptyCart from "../utils/cart-empty";
import {
  httpGetStates,
  httpGetCities,
  httpAddShippingAddress,
  httpGetShippingAddress,
  httpDeleteShippingAddress,
  httpUpdateShippingAddress,
  httpCreateOrder,
  httpCreateOrderOnline,
} from "../apimethod";
import { toast } from "react-toastify";
import { UserContext } from "../context/User";
import DeletePop from "../utils/delete-pop";

const Check_Out = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const { cart, setLoader, refreshCartState, isAnyStockEmpty, removeFromCart } =
    useContext(CartContext);
  const { user } = useContext(UserContext);
  const token = localStorage.getItem("token");

  //states
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [townCity, setTownCity] = useState("");
  const [state, setState] = useState("");
  const [pin, setPin] = useState("");

  //check any item is out of stock

  //errors messages
  const [errorName, setErrorName] = useState("");
  const [errorMobile, setErrorMobile] = useState("");
  const [errorAddress, setErrorAddress] = useState("");
  const [errorTownCity, setErrorTownCity] = useState("");
  const [errorState, setErrorState] = useState("");
  const [errorPin, setErrorPin] = useState("");

  //shipping address states

  const [statesList, setStatesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);

  //address list

  const [addressList, setAddressList] = useState([]);
  const [addedAddress, setAddedAddress] = useState({});

  //delete Pop

  const [showPopup, setShowPopup] = useState(false);
  const closeDeletePopup = () => setShowPopup(false);
  const openDeletePopup = () => setShowPopup(true);
  const [deleteId, setDeleteId] = useState("");

  //address update

  const [btnType, setBtnType] = useState("submit");

  //for update address

  const [forUpdateAddress, setForUpdateAddress] = useState(null);

  //for update address  Id
  const [addressId, setAddressId] = useState("");

  //selected Shipping address

  const [selectShippingAddress, setSelectShippingAddress] = useState("");

  //payment method

  const [paymentMethod, setPaymentMethod] = useState(2);

  //open submit modal
  const handleShow = () => {
    clearStates();
    setBtnType("submit");
    setShow(true);
  };

  const navigate = useNavigate();

  useEffect(() => {
    setLoader(true);
    httpGetStates(token).then((res) => {
      if (!res.data) {
        setLoader(false);
        toast.error("states not fetched");
      } else {
        setLoader(false);
        setStatesList(res.data.data);
      }
    });
  }, []);

  useEffect(() => {
    setLoader(true);
    httpGetShippingAddress(token).then((res) => {
      if (!res.data) {
        setLoader(false);
        toast.error("address not fetched");
      } else {
        setLoader(false);
        setAddressList(res.data.data);
        if (res.data.data.length == 1) {
          setSelectShippingAddress(res.data.data[0].id);
        } else {
          setSelectShippingAddress("");
        }
      }
    });
  }, [addedAddress]);

  useEffect(() => {
    if (state) {
      setLoader(true);
      setTownCity("");
      httpGetCities({ state_id: state }, token).then((res) => {
        if (!res.data) {
          toast.error("cities not fetched");
          setLoader(false);
        } else {
          setLoader(false);
          setCitiesList(res.data.data);
          if (forUpdateAddress) {
            setTownCity(forUpdateAddress.city_id);
          }
        }
      });
    }
  }, [state]);

  const clearStates = () => {
    setName("");
    setMobile("");
    setAddress("");
    setTownCity("");
    setState("");
    setPin("");
    //clear error
    setErrorName("");
    setErrorMobile("");
    setErrorAddress("");
    setErrorTownCity("");
    setErrorState("");
    setErrorPin("");
  };

  const fetchTotal = () => {
    let total = 0;

    if (cart) {
      cart.forEach((el) => {
        total += parseInt(el.quantity) * parseInt(el.mrp);
      });
    }

    return total;
  };

  const submitForm = (e) => {
    e.preventDefault();
    let isValid = true;

    //name validation

    if (!name) {
      isValid = false;
      setErrorName("Mandatory Name");
    } else if (!isName(name)) {
      isValid = false;
      setErrorName("Invalid Name");
    } else {
      setErrorName("");
    }

    // mobile No validation

    if (!mobile) {
      isValid = false;
      setErrorMobile("Mandatory Mobile No");
    } else if (!isMobileNo(mobile)) {
      isValid = false;
      setErrorMobile("Invalid Mobile No");
    } else {
      setErrorMobile("");
    }

    if (!address) {
      isValid = false;
      setErrorAddress("Mandatory Address");
    } else {
      setErrorAddress("");
    }

    if (!townCity) {
      isValid = false;
      setErrorTownCity("Mandatory Town City");
    } else {
      setErrorTownCity("");
    }

    if (!state) {
      isValid = false;
      setErrorState("Mandatory State");
    } else {
      setErrorState("");
    }

    if (!pin) {
      isValid = false;
      setErrorPin("Mandatory Pin");
    } else if (pin.length != 6) {
      isValid = false;
      setErrorPin("Invalid Pin");
    } else {
      setErrorPin("");
    }

    if (isValid) {
      if (btnType == "submit") {
        const reqBody = {
          address,
          city: townCity,
          state: state,
          postal_code: pin,
          user_id: user.id,
          recipient_name: name,
          recipient_mobile: mobile,
        };
        requestForUpdateAndAdded(httpAddShippingAddress, reqBody);
      } else {
        const reqBody = {
          id: addressId,
          user_id: user.id,
          address: address,
          city: townCity,
          state: state,
          postal_code: pin,
          recipient_name: name,
          recipient_mobile: mobile,
        };
        requestForUpdateAndAdded(httpUpdateShippingAddress, reqBody);
      }
    }
  };

  const requestForUpdateAndAdded = (method, body) => {
    setLoader(true);
    method(body, token).then((res) => {
      if (!res.data) {
        toast("have some issues");
        setLoader(false);
      } else {
        handleClose();
        setLoader(false);
        setAddedAddress(res.data);
        clearStates();
      }
    });
  };

  const deleteAddress = (id) => {
    setLoader(true);
    httpDeleteShippingAddress({ id }, token).then((res) => {
      if (!res.data) {
        setLoader(false);
        toast.error("Delete Address Issue");
      } else {
        setLoader(false);
        closeDeletePopup();
        setAddedAddress(res.data);
      }
    });
  };

  const deleteAddressMiddleware = (id) => {
    setDeleteId(id);
    openDeletePopup();
  };

  const openUpdateModal = (el) => {
    console.log(el);
    setBtnType("update");
    setForUpdateAddress(el);
    setAddressId(el.id);
    setName(el.recipient_name);
    setMobile(el.recipient_mobile);
    setAddress(el.address);
    setState(el.state_id);
    setPin(el.postal_code);
    setShow(true);
  };

  const stateHandler = (e) => {
    setForUpdateAddress(null);
    setState(e.target.value);
  };

  const createOnlineOrder = () => {
    setLoader(true);
    httpCreateOrderOnline(
      {
        // amount: fetchTotal(),
        shippingAddressId: selectShippingAddress,
      },
      token
    ).then((res) => {
      if (!res.data) {
        toast.error(res.error.response.data.message);
        if (res.error.response.data.refresh == "true") {
          refreshCartState();
        }
        setLoader(false);
      } else {
        setLoader(false);
        window.open(res.data.payment_link, "_self");
      }
    });
  };

  const getAllQuantity = () => {
    let count = 0;
    if (cart) {
      cart.forEach((el) => {
        count += el.quantity;
      });
    }
    return count;
  };

  const createOrder = () => {
    // if (paymentMethod == 1 && cart && cart.length > 0) {
    //   setLoader(true);
    //   httpCreateOrder(
    //     {
    //       shipping_address_id: selectShippingAddress,
    //       payment_type: paymentMethod,
    //       // amount: fetchTotal(),
    //     },
    //     token
    //   ).then((res) => {
    //     if (!res.data) {
    //       toast.error(res.error.response.data.message);
    //       if (res.error.response.data.refresh == "true") {
    //         refreshCartState();
    //       }
    //       setLoader(false);
    //     } else {
    //       setLoader(false);
    //       refreshCartState();
    //       navigate(`/place-order/${res.data.order_no}`, { replace: true });
    //     }
    //   });
    // } else

    if (cart && cart.length > 0) {
      createOnlineOrder();
    } else {
      toast.warn("Empty cart!");
    }
  };

  return (
    <>
      <DeletePop
        show={showPopup}
        handleClose={closeDeletePopup}
        submit={deleteAddress}
        id={deleteId}
      />
      <nav aria-label="breadcrumb" class="breadcrumbs large-font">
        <ol class="breadcrumb pt-2 ps-3">
          <li class="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Checkout
          </li>
        </ol>
      </nav>
      <div className="py-5">
        <div className="container">
          <h4 class="mb-5 heading-text mx-lg-auto text-center">
            Product Checkout
          </h4>
          <div className="row checkout-page">
            <div className="col-md-7">
              <h4 className="mt-3 mb-4">Your order</h4>
              {cart && (
                <Table>
                  <thead className="bg-light-blue">
                    <tr>
                      <th className="fw-semibold">Image</th>
                      <th className="fw-semibold">Name & Quantity</th>
                      <th className="fw-semibold">Subtotal</th>
                      <th className="fw-semibold">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cart.map((el) => {
                      return (
                        <tr>
                          <td>
                            <Link to={"/product/" + el.id}>
                              <div className="cart-product-img">
                                <img
                                  src={imageUrl + el.image}
                                  alt=""
                                  class="img-fluid"
                                  style={{
                                    opacity: el.is_active == 2 ? 0.2 : 1,
                                  }}
                                />
                                <div
                                  style={{ color: "red", fontWeight: "bolder" }}
                                >
                                  {el.stock_quantity == 0 && "Out Of Stock"}
                                </div>
                              </div>
                            </Link>
                          </td>
                          <td>
                            {el.book_title}
                            <span className="ps-2">× {el.quantity}</span>
                          </td>
                          <td>₹{el.mrp * el.quantity}</td>

                          <td>
                            <button
                              className="btn btn-danger"
                              onClick={() => removeFromCart(el.id)}
                            >
                              X
                            </button>
                          </td>
                        </tr>
                      );
                    })}

                    <tr>
                      <td></td>
                      <td className="text-end text-redc fw-semibold">
                        TOTAL =
                      </td>

                      <td className="fw-semibold">₹{fetchTotal()}</td>
                    </tr>
                    <tr>
                      <td className="text-end text-redc fw-semibold">
                        Note: ₹60 delivery charge per quantity
                      </td>
                      <td className="text-end text-redc fw-semibold">
                        SHIPPING CHARGE =
                      </td>

                      <td className="fw-semibold">₹{getAllQuantity() * 60}</td>
                    </tr>
                  </tbody>
                </Table>
              )}
              {!cart && <EmptyCart />}
            </div>
            <div className="col-md-5">
              <div className="billing-details mb-3">
                <div className="billing-details-title mt-3 mb-4">
                  <h4>Billing Details</h4>
                </div>
                <div className="billing-address">
                  <p className="text-redc fs-12 mb-4">
                    The shipments deliverable to our patrons are experiencing
                    delays due to disruptions in the logistical supply chain,
                    caused by the ongoing pandemic. We are working to get this
                    resolved. In the meantime, please bear with us.
                  </p>
                  <div className="">
                    <div className="add-billing-address-btn d-flex justify-content-between mb-4">
                      <h5 className="fw-600">Select Address</h5>
                      <span className="add-address" onClick={handleShow}>
                        Add New Address
                      </span>
                    </div>
                    {addressList.length < 1 ? (
                      <div className=" pt-3 border-top">
                        <span className="text-redc fs-12">
                          Please add new shipping address
                        </span>
                      </div>
                    ) : (
                      ""
                    )}

                    {addressList.map((el) => {
                      return (
                        <div className="billing-address-detail">
                          <div className="row">
                            <div className="col-9">
                              <div class="form-check ">
                                <input
                                  onChange={(e) =>
                                    setSelectShippingAddress(e.target.value)
                                  }
                                  class="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  value={el.id}
                                  checked={selectShippingAddress == el.id}
                                />
                                <label
                                  class="form-check-label fw-semibold fs-14"
                                  for="flexRadioDefault1"
                                >
                                  {el.recipient_name}
                                </label>
                              </div>
                              <div className="billing-fulladdress ps-4">
                                <p>{el.address}</p>
                                <p>
                                  <span className="fw-semibold">Mobile:</span>
                                  {el.recipient_mobile}
                                </p>
                              </div>
                            </div>

                            <div
                              className="col-1 align-self-center"
                              onClick={() => openUpdateModal(el)}
                            >
                              <span>
                                <BiPencil className="fs-4 text-redc del" />
                              </span>
                            </div>
                            <div
                              className="col-2 align-self-center"
                              onClick={() => deleteAddressMiddleware(el.id)}
                            >
                              {selectShippingAddress != el.id ? (
                                <span>
                                  <BiTrash className="fs-4 text-redc del" />
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <h4 className="mt-3 mb-4">Payment Method</h4>
              <div className="payment">
                <div className="payment-option">
                  {/* <div class="form-check mb-3">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault2"
                      onChange={(e) => setPaymentMethod(e.target.value)}
                      value={1}
                    />
                    <label
                      class="form-check-label fw-semibold fs-14"
                      for="flexRadioDefault1"
                    >
                      Cash on delivery
                    </label>
                    <p>Pay with cash upon delivery.</p>
                  </div> */}
                  <div class="form-check mb-3 border-top pt-3">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault2"
                      checked={true}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                      value={2}
                    />
                    <label
                      class="form-check-label fw-semibold fs-14"
                      for="flexRadioDefault1"
                    >
                      Credit Card/Debit Card/NetBanking{" "}
                      <span>
                        <img
                          src="assets/img/payment.svg"
                          alt=""
                          class="img-fluid mw-135"
                        />
                      </span>
                    </label>
                    {/* <p>Pay securely by Credit or Debit card or Internet Banking through Razorpay.</p> */}
                  </div>
                </div>
                {!selectShippingAddress || !paymentMethod ? (
                  <div className=" pt-3 border-top">
                    <span className="text-redc fs-12">
                      Please select shipping address and payment method{" "}
                    </span>
                  </div>
                ) : (
                  ""
                )}
                <div className="checkout-btn text-end  pt-3">
                  <Button
                    className="button-87"
                    to="/place-order"
                    disabled={
                      !selectShippingAddress ||
                      !paymentMethod ||
                      isAnyStockEmpty()
                    }
                    onClick={createOrder}
                  >
                    Place order
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {btnType == "submit" ? "Add New Address" : "Update Address"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="checkout-popup">
          <Form as={Row}>
            <Form.Text className="mt-2 mb-3 fw-semibold">
              CONTACT DETAILS
            </Form.Text>
            <Form.Group className="mb-2" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Name*"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <span
                className={`ps-2 fs-12 ${!errorName && "d-none"}`}
                style={{ color: "red" }}
              >
                {errorName}
              </span>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Control
                type="number"
                placeholder="Mobile No*"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
              <span
                className={`ps-2 fs-12 ${!errorMobile && "d-none"}`}
                style={{ color: "red" }}
              >
                {errorMobile}
              </span>
            </Form.Group>

            <Form.Text className="mb-3 fw-semibold">ADDRESS</Form.Text>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Address (House No, Building, Street, Area)*"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
              <span
                className={`ps-2 fs-12 ${!errorAddress && "d-none"}`}
                style={{ color: "red" }}
              >
                {errorAddress}
              </span>
            </Form.Group>

            <Form.Group
              sm="6"
              as={Col}
              className="mb-3"
              controlId="formBasicPassword"
            >
              <Form.Select
                aria-label="Default select example"
                value={state}
                onChange={stateHandler}
              >
                <option value="">states</option>
                {statesList.map((el) => {
                  return <option value={el.id}>{el.name}</option>;
                })}
              </Form.Select>
              <span
                className={`ps-2 fs-12 ${!errorState && "d-none"}`}
                style={{ color: "red" }}
              >
                {errorState}
              </span>
            </Form.Group>

            <Form.Group
              sm="6"
              as={Col}
              className="mb-3"
              controlId="formBasicEmail"
            >
              <Form.Select
                aria-label="Default select example"
                value={townCity}
                onChange={(e) => setTownCity(e.target.value)}
              >
                <option value={""}>cities</option>
                {citiesList.map((el) => {
                  return <option value={el.id}>{el.name}</option>;
                })}
              </Form.Select>
              <span
                className={`ps-2 fs-12 ${!errorTownCity && "d-none"}`}
                style={{ color: "red" }}
              >
                {errorTownCity}
              </span>
            </Form.Group>

            <Form.Group
              sm="12"
              as={Col}
              className="mb-3"
              controlId="formBasicPassword"
            >
              <Form.Control
                type="number"
                placeholder="Pin*"
                value={pin}
                onChange={(e) => setPin(e.target.value)}
              />
              <span
                className={`ps-2 fs-12 ${!errorPin && "d-none"}`}
                style={{ color: "red" }}
              >
                {errorPin}
              </span>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-grid gap-2">
          <Button variant="primary" onClick={submitForm}>
            {btnType == "submit" ? "ADD ADDRESS" : "UPDATE ADDRESS"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Check_Out;
