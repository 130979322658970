import  {apis,request,methods} from "../request";

const header = {
  headers: { Authorization: null },
};


export const httpSignup = (body)=>{
   return request(methods.post,apis.signUp,body)
}
export const httpSignin=(body)=>{
    return request(methods.post,apis.login,body)
}

export const httpUpdateProfile = (body,token) => {
    header.headers.Authorization = `Bearer ${token}`;
    return request(methods.post,apis.updateProfile,body,header)
}

export const httpGetProfile = (id, token) => {
  header.headers.Authorization = `Bearer ${token}`;
  return request(methods.get, apis.getUserProfile+id,null, header);
};

export const httpChangePassword = (body,token) => {
  header.headers.Authorization = `Bearer ${token}`;
  return request(methods.post,apis.updatePassword,body,header)
}


export const httpForgotPassword = (body) =>{
  return request(methods.post, apis.forgotPassword, body);
}

export const httpVerifyOtp = (body) => {
   return request(methods.post, apis.verifyOtp, body);
} 

export const httpCreateNewPassword = (body) => {
  return request(methods.post,apis.createNewPassword,body)
}