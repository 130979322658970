import React, { useState, useContext } from "react";
import signin from "../assets/img/others/signin.png";
import logo from "../assets/img/logo/bfc-logo-removebg-preview.png";
import { GiArchiveRegister } from "react-icons/gi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../context/User";
import { CartContext } from "../context/CartContext";
import {isEmail,isPassword} from "../utils/validations"

const Signin = () => {
  const navigate = useNavigate();
  const {state} = useLocation()
  const { signIn } = useContext(UserContext);
  const { fetchExistingCart } = useContext(CartContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");

  const checkValidation = () => {
    
    let hasError = false;
    
    //email validation
    if (email == "") {
      setErrorEmail("Mandatory Email");
      hasError = true;
    } else if (!isEmail(email)) {
      setErrorEmail("Invalid Email");
      hasError = true;
    } else {
      setErrorEmail("");
    }

    // password validation

    if (password == "") {
      setErrorPassword("Mandatory Password");
      hasError = true;
    } else if (password < 6) {
      setErrorPassword("Please enter valid password");
      hasError = true;
    } else {
      setErrorPassword("");
    }
    
    return hasError
  };

  const userLogin = async (e) => {
    e.preventDefault();

    if (checkValidation()) {
      return;
    }
    
    signIn({ email, password },state?.path?state.path:"/").then((res) => {
      if (res) {
        fetchExistingCart(res);
      }
    });
  };
  
  return (
    <>

      <section className=" login-bg pt-3">
        <div className="container">
          <div className="brand-logo ">
            <a href="/">
              <img src={logo} alt="" srcset="" className="img-fluid" />
            </a>
          </div>
          <div className="row align-items-stretch pt-3">
            <div className="col-md-7 ">
              <img src={signin} alt="" srcset="" className="img-fluid" />
            </div>
            <div className="col-md-5 align-self-center">
              <h4 className="pb-3 text-redc ">Login using your credential</h4>
              <form>
                <div className="form-group mb-4">
                  <input
                    class="search-input si"
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter Your Email"
                  />
                  <span style={{ color: "red" }}>{errorEmail}</span>
                </div>
                <div className="form-group mb-4">
                  <input
                    class="search-input si"
                    type="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter Your Password"
                  />
                  <span style={{ color: "red" }}>{errorPassword}</span>
                </div>
                <Link to={"/forgot-password"} className="login-a">
                  Forgot Password
                </Link>

                <div className="form-group mt-4">
                  <button
                    type="submit"
                    class="button-86 fw-bold"
                    onClick={userLogin}
                    role="button"
                  >
                    Sign In <GiArchiveRegister className="ms-2 fs-5" />
                  </button>
                </div>
                <hr />
                <p className="fs-14 text-rale">
                  Don't have an account? New customer?
                  <Link to="/signup" className="login-a">
                    <b>Create your account</b>
                  </Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Signin;
