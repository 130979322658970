import React from "react";
import { RxCross2 } from "react-icons/rx";
import { MdOutlineRemoveShoppingCart } from "react-icons/md";
import { Link } from "react-router-dom";
import { imageUrl } from "../request"
import EmptyCart from "../utils/cart-empty"

const CartBox = ({ cart, removeFromCart ,token}) => {
  const fetchTotal = () => {
    let total = 0;

    if (cart) {
      cart.forEach((el) => {
        total += parseInt(el.quantity) * parseInt(el.mrp);
      });
    }

    return total;
  };

  return (
    <>
      <div className="cart-box">
        {cart ?
          cart.map((el, i) => {
            if (i == 5) return;
            return (
              <div className="card-info position-relative">
                <Link to={"/product/" + el.id}>
                  <div className="d-flex p-3">
                    <img
                      src={imageUrl+el.image}
                      alt="image"
                      className="img-fluid wd-80"
                    />
                    <div className="product-title ps-4 pt-3">
                      <h3 className="fs-18">{el.book_title}</h3>
                      <span className="text-black fs-14">
                        {el.quantity} ×{" "}
                        <span>
                          ₹ {el.mrp} ={" "}
                          {parseInt(el.quantity) * parseInt(el.mrp)}
                        </span>
                      </span>
                    </div>
                  </div>
                </Link>
                <span
                  className="cartcut-icon"
                  onClick={() => removeFromCart(el.id)}
                >
                  <RxCross2 />
                </span>
              </div>
            );
          }) : <div style={{padding:'10px'}}> <EmptyCart/></div>
        }

        <div className="card-total text-center mx-3 fs-14">
          <span className="fw-semibold fs-11">Sub Total:</span> ₹ {fetchTotal()}
        </div>
        <div className="d-flex justify-content-around my-2">
          <Link
            to={`${token ? "/checkout" : "/signin"}`}
            state={token ? null : { path: "/checkout" }}
            className="cart-btn bg-danger"
          >
            Checkout
          </Link>
          <Link to="/cart" className="cart-btn button-87">
            View Cart{" "}
          </Link>
        </div>
      </div>
    </>
  );
};
export default CartBox;
